.ant-notification {
	left: 0;
	right: 0;
	top: 0 !important;
	margin-inline-end: 0;

	.notification {
		border-radius: 0;
		margin: auto;
		padding: 0;
		min-width: 100vw;
		width: 100%;
		min-height: 48px;
		box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
		display: flex;
		justify-content: center;
		align-items: center;

		.ant-notification-notice-icon-error {
			//display: none;
		}

		.ant-notification-notice-close {
			top: 14px;
		}

		&__error {
			padding: 12px 24px !important;
			background: #ff776f;

			.ant-notification-notice-message {
				margin: 0;
				padding: 0;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				color: #fff;
			}
		}

		&__success {
			background: #58c65c;
			padding: 12px 24px !important;

			.ant-notification-notice-message {
				margin: 0;
				padding: 0;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				color: #fff;
			}
		}
	}
}
