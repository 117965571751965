.home-page {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 24px;

	&__body {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&__side {
		max-width: 336px;
		display: flex;
		flex-direction: column;
		grid-gap: 24px;
		//flex-wrap: wrap;

		@media screen and (width <= 768px) {
			max-width: 100%;
			flex: 1;
		}
	}
}

.lc-verification-template {
	display: flex;

	&__side {
		display: flex;
	}

	&__container {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		flex: 1;
		background: #edf2ff;
	}

	&__body {
		padding: 0 24px;
		display: flex;
		//height: calc(100% - 142px);
		height: calc(100% - 88px);
	}
}
