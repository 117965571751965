.step-list {
	display: flex;
	flex-direction: column;

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 32px;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.02em;
		color: #a09ef2;
	}

	&__item:not(:last-child)::after {
		content: '';
		position: absolute;
		top: 36px;
		left: 14px;
		width: 2px;
		height: 24px;
		background-color: #fff;
	}

	&__item-active:not(:last-child)::after,
	&__item-active ~ &__item::after {
		background-color: #a09ef2;
	}

	&__index {
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid #a09ef2;
		border-radius: 50px;
	}

	&__index-move {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #fff;
		border: 2px solid #fff;
	}

	&__index-active {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #3843ed;
		background-color: #fff;
	}

	&__name {
		cursor: pointer;
		margin: 0 12px;
	}

	&__item-move {
		font-size: 14px;
		color: #fff;
	}

	&__item-active {
		font-size: 16px;
		font-weight: 700;
	}
}
