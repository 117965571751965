%text {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #303842;
}

.btn-nav {
	padding: 8px;
	width: 100%;
	min-height: 56px;
	display: flex;
	align-items: center;
	background: #edf2ff;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
	transition: background 300ms linear;

	&__plus {
		cursor: pointer;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		box-shadow: 0 1px 10px rgb(0 0 0 / 8%);
		border-radius: 16px;
	}

	&__text {
		margin: 0 8px;

		@extend %text;
	}

	&__disabled {
		background: #d2d8e8;
	}

	&:hover {
		//background: #4e5af2;
		//transition: background 300ms linear;
	}

	&:hover &__text {
		//color: #fff;
		//transition: color 300ms linear;
	}
}

// TYPES buttons

// vancancy
.btn-nav.add-vacancy {
	background: #ffeebf;

	.btn-nav__plus {
		svg {
			fill: #ffc864;
		}
	}
}

// meeting
.btn-nav.add-meeting {
	background: #dbdaff;

	.btn-nav__plus {
		svg {
			fill: #8d8bed;
		}
	}
}

// task
.btn-nav.add-task {
	background: #ffe4e2;

	.btn-nav__plus {
		svg {
			fill: #ff776f;
		}
	}
}

// disabled
.btn-nav.btn-nav-disabled {
	background: #d2d8e8;

	.btn-nav__plus {
		svg {
			fill: #d2d8e8;
		}
	}
}

// canditate
.btn-nav.add-candidate {
	background: #c4f1c8;

	.btn-nav__plus {
		svg {
			fill: #84df88;
		}
	}
}

// hover
.btn-nav.btn-nav-disabled:hover {
	background: #d2d8e8;

	.btn-nav__plus {
		svg {
			fill: #d2d8e8;
		}
	}
}

.btn-nav.add-meeting:hover {
	background: #8d8bed;
	transition: background 300ms linear;

	.btn-nav__plus {
		svg {
			fill: #8d8bed;
		}
	}
}

.btn-nav.add-task:hover {
	background: #ff776f;
	transition: background 300ms linear;

	.btn-nav__plus {
		svg {
			fill: #ff776f;
		}
	}
}

.btn-nav.add-vacancy:hover {
	background: #ffdd90;
	transition: background 300ms linear;

	.btn-nav__plus {
		svg {
			fill: #ffc864;
		}
	}
}

.btn-nav.add-candidate:hover {
	background: #71d575;
	transition: background 300ms linear;

	.btn-nav__plus {
		svg {
			fill: #84df88;
		}
	}
}
