.tooltip-confirm-phone {
	&__title {
		//margin-bottom: 8px;
	}

	&__ul {
		margin: 8px 12px;
	}

	&__li {
		margin-bottom: 8px;
		list-style: decimal;
	}

	&__footer {
		transition: color 200ms linear;
		display: flex;
		justify-content: flex-start;
		color: #8d8bed;
	}

	&__footer:hover {
		transition: color 200ms linear;
		color: #6d6be5;
		text-decoration: underline;
	}

	&__footer:focus {
		transition: color 200ms linear;
		color: #3843ed;
		text-decoration: underline;
	}
}
