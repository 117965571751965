%text {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #8d97b0;
	text-decoration-line: none;
}

.timeout-block {
	display: flex;
	align-items: center;
	justify-content: center;

	&__time {
		margin-left: 5px;

		@extend %text;
	}
}
