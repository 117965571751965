.table-wrapper {
	width: 100%;
	overflow: auto;
	box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
	border-radius: 8px;

	@media screen and (width <= 1024px) {
		width: 90vw;
		display: none;
	}
}

.app-table {
	@media screen and (width <= 1024px) {
		width: 1024px;
	}

	.ant-table-title {
		border-bottom: 1px solid #e4e4ef;
	}

	.ant-table-content {
		min-height: 477px;
	}

	.ant-table-thead > tr > th {
		padding: 11px 18px !important;
		background-color: #fff;
		border: none;
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		color: #20272e;
	}

	.ant-table-cell {
		border: none;
	}

	&__empty {
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgb(237 242 255 / 70%);
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		color: #303842;
	}

	&__title {
		padding: 0 24px;
		height: 64px;
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #20272e;
	}

	&__block {
		position: absolute;
		inset: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__text {
		height: 24px;
		width: 24px;
		display: flex;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		color: #303842;
		transition: all 500ms cubic-bezier(0.65, 0.05, 0.36, 1);

		&.active {
			background-color: red;
			color: #fff;
		}
	}

	&__remainder {
		position: absolute;
		top: 50%;
		left: 60%;
		transform: translateY(-50%);
		font-weight: 600;
		font-size: 14px;
		color: #6d6be5;
		transition: all 500ms cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	&__column::before {
		display: none;
	}

	&__column {
		.ant-table-column-sorter {
			display: none;
		}
	}
}

.ant-table.ant-table-middle .ant-table-content tfoot > tr,
.ant-table.ant-table-middle
	.ant-table-content
	.ant-table-thead
	> tr
	.ant-table.ant-table-middle
	.ant-table-content
	.ant-table-tbody
	> tr {
	padding: 0 24px;
}

.ant-table-wrapper .ant-table-empty .ant-table-tbody > tr > td {
	border-bottom: none;
}

.ant-table.ant-table-middle .ant-table-content .ant-table-title,
.ant-table.ant-table-middle .ant-table-content .ant-table-footer,
.ant-table.ant-table-middle .ant-table-content tfoot > tr > td,
.ant-table.ant-table-middle .ant-table-content tfoot > tr > th,
.ant-table.ant-table-middle .ant-table-content .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-content .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead > tr > td,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > td {
	padding: 8px 16px;

	&.lc-verification-companies-table__column-status {
		padding-left: 0;
		padding-right: 0;
	}
}

.ant-table-wrapper .ant-table .ant-table-container .ant-table-tbody td:first-child,
.ant-table-wrapper .ant-table .ant-table-container table > thead > tr:first-child > *:first-child {
	border-start-start-radius: 0;
	padding: 8px 0 8px 24px;
}
