.input-default.input-default__checkbox-wrap {
	display: flex;
	width: 100%;
	justify-content: space-between;

	.ant-checkbox-wrap {
		display: flex;
		align-items: center;
		gap: 8px;

		label {
			color: #20272e;
			font-size: 12px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0;
			text-align: left;
		}

		.ant-checkbox-label {
			font-size: 10px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0;
			text-align: left;
			color: #8d97b0;
			display: flex;
		}
	}

	.ant-checkbox {
		.ant-checkbox-inner {
			border: 2px solid #d2d8e8;
		}

		&.ant-checkbox-checked {
			.ant-checkbox-inner {
				border: 2px solid #71d475;
				background: #71d475;
			}
		}
	}
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
	.ant-checkbox-checked:not(.ant-checkbox-disabled) {
		.ant-checkbox-inner {
			border: 2px solid #71d475;
			background: #71d475;
		}
	}
}
