/* stylelint-disable no-descending-specificity */
%name {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #6d7895;
}

.main-menu {
	display: flex;
	flex-direction: column;

	@media screen and (width <= 1024px) {
		display: none;
	}
	//max-width: 240px;

	&__footer {
		padding: 38px 36px 10px;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	}
}

.menu-list {
	width: 240px;
	display: flex;
	flex-direction: column;
	transition: width 200ms ease-in-out;

	svg {
		color: #d2d8e8;
	}

	&__item {
		padding: 15px 12px;
		margin: 0 12px;
		width: auto;
		height: 48px;
		display: flex;
		align-items: center;
		cursor: pointer;

		&-name {
			margin-left: 11px;
			width: auto;
			opacity: 1;
			transition: all 0.2s;

			@extend %name;

			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			letter-spacing: 0.01em;
		}

		&-active {
			svg {
				color: #6d6be5;
			}

			background: rgb(237 242 255 / 70%);
			border-radius: 8px;
		}

		&-active &-name {
			color: #6d6be5;
		}
	}

	&__item-disabled {
		cursor: default;
	}

	&__item:hover {
		svg {
			color: #6d6be5;
			transition: all 0.2s;
			fill: #6d6be5;
		}
	}

	&__item:hover &__item-name {
		color: #6d6be5;
	}

	&__item-disabled:hover {
		svg {
			color: #d2d8e8;
			fill: #d2d8e8;
		}
	}

	&__item-disabled:hover &__item-name {
		color: #6d7895;
	}

	&__is-collapsed {
		width: 76px;
		transition: width 200ms ease-in-out;
	}

	&__is-collapsed &__item {
		justify-content: center;
	}

	&__is-collapsed &__item-name {
		//display: none;
		margin-left: 0;
		width: 0;
		opacity: 0;
		transition: all 0s;
	}
}
