.information-help {
	&__wrap {
		width: 352px;
		padding: 40px 24px 32px;
		border-radius: 16px;
		gap: 24px;
		background: #fff;
		margin-bottom: 48px;
		margin-top: 16px;
		position: relative;

		.close {
			height: 24px;
			display: block;
			width: 24px;
			position: absolute;
			top: 12px;
			right: 12px;
			cursor: pointer;

			svg {
				fill: #8d97b0;
			}

			&:hover svg {
				fill: #3843ed;
			}
		}
	}

	&__image-wrap {
		background: #8d8bed;
		width: 100%;
		height: 140px;
		border-radius: 16px;
		margin-bottom: 24px;
		position: relative;
		overflow: visible;

		img {
			position: absolute;
			bottom: 0;
			left: 76px;
		}
	}

	&__text {
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0;
		text-align: left;
		color: #303842;
		margin-bottom: 12px;
	}

	&__description {
		font-size: 14px;
		font-weight: 500;
		line-height: 17px;
		letter-spacing: 0;
		text-align: left;
		color: #47525f;
	}
}
