%emptyText {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #303842;
}

.companies-container {
	max-width: 576px;

	@media screen and (width <= 768px) {
		max-width: 90vw;
	}

	&__empty-text {
		@extend %emptyText;
	}

	.companies-list {
		margin-top: 12px;
	}
}
