::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background: transparent;
}

::-webkit-scrollbar-track {
	background: transparent;
	margin: 25px 0;
}

::-webkit-scrollbar-thumb {
	background: rgb(104 106 124 / 40%);
	border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
	box-shadow: inset 0 0 1px rgb(104 106 124 / 90%);
}

::-webkit-scrollbar-button {
	display: none;
}
