%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

%titleDesc {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #303842;
}

%role {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: #47525f;
}

%status {
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #303842;
}

%svg {
	svg {
		cursor: pointer;
		transition: all 0.2s;
		fill: #d2d8e8;
	}

	svg:hover {
		transition: all 0.2s;
		fill: #3843ed;
	}
}

.header-profile {
	padding: 24px;
	width: 100%;
	display: flex;
	background: #fff;
	box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
	border-radius: 8px;
	grid-gap: 24px;

	&__name {
		display: flex;

		&-title {
			@extend %title;
		}

		&-desc {
			margin: 0 15px 0 4px;

			@extend %titleDesc;
		}

		svg {
			cursor: pointer;
			transition: all 0.2s;
			fill: #d2d8e8;
			stroke: #d2d8e8;
		}

		svg:hover {
			transition: all 0.2s;
			fill: #3843ed;
			stroke: #3843ed;
		}
	}

	&__role {
		display: flex;
		align-items: center;
		min-height: 24px;

		@extend %role;
		@extend %svg;

		&-icon {
			margin: 7px 0 0 8px;
			display: flex;
		}
	}

	&__status {
		margin: 4px 0 8px;
		display: flex;
		align-items: center;

		&-text {
			transition: box-shadow 200ms linear;
			padding: 12px 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #ffc864;
			border-radius: 20px;

			@extend %status;

			&-1 {
				color: #58c65c;
				background: #e3f6e3;
			}

			&-2 {
				color: #5e6bf6;
				background: #edf2ff;
			}

			&-3 {
				color: #ffc864;
				background: #fff8e9;
			}

			&-4 {
				color: #9f64ff;
				background: #efe9ff;
			}

			&-5 {
				color: #e964ff;
				background: #fce9ff;
			}

			&-6 {
				color: #64b5ff;
				background: #e9fbff;
			}
		}

		&-text:hover {
			cursor: pointer;
			transition: 200ms linear;
			box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		flex: 1;
		grid-gap: 24px;
	}

	&__avatar {
		position: relative;
		width: 110px;
		height: 110px;
		display: flex;
		object-fit: cover;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background: #8d8bed;
	}

	&__avatar-uploader {
		z-index: 1;
		width: 110px;
		height: 110px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}

	&__row {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__column {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

.header-profile .btn-default {
	width: 180px;
}
