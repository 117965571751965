@import './variables';
//$fontUrl: '../';
@mixin font($font_name, $file_name, $weight, $style) {
	@font-face {
		font-family: $font_name;
		font-display: swap;
		src:
			url('fonts/#{$file_name}.woff') format('woff'),
			url('fonts/#{$file_name}.woff2') format('woff2');
		font-weight: #{$weight};
		font-style: #{$style};
	}
}

@mixin adaptiveText($pcSize, $mobSize) {
	$addSize: $pcSize - $mobSize;
	$addMobSize: $addSize + $addSize * 0.7;

	@media (width <= 767px) {
		font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth + px}));
	}

	@media (width >= 767px) {
		font-size: calc(#{$mobSize + px} + #{$addMobSize} * (100vw / #{$maxWidth + px}));
	}
}

@mixin adaptiveFont() {
	font-size: calc(24px + 16 * ((100vw - 320px) / (1280 - 320)));
}

@mixin hide-world() {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
