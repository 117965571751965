%title {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #6d7895;
}

%count {
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #303842;
}

.stats-info-block {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 24px;

	&__box {
		padding: 16px;
		flex: 1;
		max-width: 276px;
		display: flex;
		justify-content: space-between;
		background: #fff;
		border-radius: 16px;
		box-shadow: 0 2px 4px rgb(0 0 0 / 8%);

		&-body {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		&-title {
			@extend %title;
		}

		&-counts {
			display: flex;
			align-items: flex-end;
			grid-gap: 8px;
		}

		&-count {
			@extend %count;
		}

		&-icon {
			min-width: 48px;
			height: 48px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 10px;

			&-companies {
				background: #edf2ff;
			}

			&-await {
				background: #fff8e9;
			}

			&-verification {
				background: #e3f6e3;
			}

			&-reject {
				background: #ffe4e2;
			}
		}
	}
}
