/* stylelint-disable value-no-vendor-prefix */
@import './datepicker';
@import './select';
@import './switch';
@import './checkbox';

%rows {
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

%label {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #20272e;
	white-space: nowrap;
}

%placeholder {
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
	color: #8d97b0;
}

%input {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #20272e;
}

%error {
	font-weight: 400;
	font-size: 10px;
	line-height: 120%;
	color: #ff776f;
}

.input-default {
	position: relative;
	margin-bottom: 24px;

	&.input-select-custom,
	&.input-select-dropdown {
		.input-default__input {
			flex-direction: column;
		}
	}

	label {
		display: flex;
		align-items: center;

		@extend %label;

		.default-tooltip,
		.info-icon {
			display: flex;
			align-items: center;
			margin-left: 8px;

			svg {
				fill: #d2d8e8;

				&:hover {
					cursor: pointer;
					fill: #6d6be5;
					transition: all 0.5s;
				}
			}
		}
	}

	input {
		outline: none;
		padding: 0 16px;
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: column;
		border: 1px solid #cbd5e2;
		box-sizing: border-box;
		border-radius: 4px;

		@extend %input;

		appearance: none;
	}

	/* Safari hide icons */

	input::-webkit-contacts-auto-fill-button {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
		position: absolute;
		right: 0;
	}

	input::placeholder {
		@extend %placeholder;
	}

	input:hover {
		border: 1px solid #4e5af2;
	}

	input:focus {
		border: 2px solid #3843ed;
		padding: 0 15px;
	}

	input:disabled {
		background: #dcdefc;
		color: #8d97b0;
		border: 1px solid #cbd5e2;
	}

	&__input {
		position: relative;
		display: flex;
		align-items: center;
	}

	.isIcon {
		padding: 0 31px 0 16px;

		&:focus {
			padding: 0 31px 0 16px !important;
		}
	}

	.isFocus:focus {
		border: 2px solid #3843ed;
		padding: 0 31px 0 16px !important;
	}

	&__icons {
		position: absolute;
		right: 0;
	}

	&__icon {
		cursor: pointer;
		margin-right: 13px;
	}

	&__error {
		border: 2px solid #ff776f !important;
		padding: 0 16px !important;
	}

	&__error:focus {
		//padding: 0 15px;
	}

	&__message {
		position: absolute;
		top: 48px;
		display: flex;
		align-items: center;

		@extend %error;

		&-icon {
			margin-right: 8px;
			display: flex;
		}

		span {
			@extend %rows;
		}
	}

	&__required {
		color: #ff776f;
	}

	&__plus {
		cursor: pointer;
		display: flex;
	}
}

.field-error-down .input-default {
	&__message {
		top: 65px;
	}
}

.input-default.margin-0 {
	margin: 0;
}
