%text {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #303842;
}

%errorText {
	font-weight: 400;
	font-size: 10px;
	line-height: 120%;
	color: #ff776f;
}

.ant-upload.ant-upload-drag {
	height: 88px;
	display: flex;
	border-radius: 8px;
	background: #fff;
}

.form__container-title {
	.ant-upload.ant-upload-drag {
		height: 24px;
		border: none;

		.ant-upload-btn {
			padding: 0;
		}
	}
}

.uploader-zone {
	&__text {
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			transform: rotate(180deg);
		}

		span {
			margin: 0 11px;

			@extend %text;
		}
	}

	&__error-text {
		margin-top: 6px;
		display: flex;
		align-items: center;

		@extend %errorText;

		svg {
			margin-left: 4px;
			margin-right: 8px;
		}
	}
}

.uploader-zone-error .ant-upload.ant-upload-drag,
.ant-upload.ant-upload-drag.uploader-zone-error {
	border-color: #ff5e55;
}

.ant-upload.ant-upload-drag.uploader-icon-zone {
	height: 24px;
	border: none;
	background: transparent;

	.ant-upload {
		padding: 0;
	}
}
