%text {
	font-weight: 500;
	font-size: 10px;
	line-height: 24px;
	color: #8d97b0;
}

.app-breadcrumb {
	display: flex;
	align-items: center;

	li {
		display: flex;
		align-items: center;
	}

	.ant-breadcrumb-separator {
		display: flex;
		align-items: center;
		margin-inline: 0;
	}

	li:first-child .ant-breadcrumb-separator {
		display: none;
	}

	&__link {
		display: flex;
		align-items: center;

		@extend %text;

		svg {
			fill: #8d97b0;
			color: #8d97b0;
			height: 21px;
			width: 21px;
			margin-right: 4px;
		}

		&:hover svg {
			cursor: pointer;
			fill: #3843ed;
		}
	}

	&__link:hover {
		color: #3843ed;
		cursor: pointer;
	}

	li:first-child &__link {
		// margin-right: 8px;
	}

	&__link-last {
		font-weight: 600;
		color: #303842;
	}

	&__link-last:hover {
		color: #303842 !important;
	}
}
