.benefits {
	&__list {
		display: grid;
		grid-template-columns: repeat(2, auto);

		@media screen and (width <= 768px) {
			grid-template-columns: repeat(auto-fill, 242px);
		}
	}

	&__item {
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #fff;
	}

	&__icon {
		margin-right: 10px;
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: url('../../../assets/image/icons/check-select.svg') no-repeat center center;
		background-size: contain;
	}
}
