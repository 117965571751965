%text {
	font-weight: 500;
	font-size: 12px;
	line-height: 120%;
	color: #303842;
}

.send-message {
	width: 100%;
	display: flex;
	flex-direction: column;

	&__textarea {
		resize: none;
		padding: 12px;
		width: 100%;
		height: 168px;
		background: #fff;
		border: 1px solid #cbd5e2;
		border-radius: 8px;

		@extend %text;
	}

	&__textarea:hover {
		border: 2px solid #8d8bed;
	}

	&__textarea:focus {
		outline: none;
		border: 2px solid #6d6be5;
	}

	&__textarea::placeholder {
		@extend %text;

		color: #8d97b0;
	}

	&__btn-text {
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: #6d7895;
	}

	&__btns {
		margin-top: 12px;
		grid-gap: 12px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.btn-default {
			width: auto;

			&__text {
				margin: 0 24px;
			}
		}
	}
}
