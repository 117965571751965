.header {
	padding: 24px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 24px;

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		@media screen and (width <= 1136px) {
			.message {
				display: none;
			}
		}

		@media screen and (width <= 768px) {
			width: 100%;
			justify-content: flex-end;
		}
	}

	&__container > * {
		margin-left: 28px;
	}

	svg {
		fill: #6d6be5;
	}

	svg:hover {
		cursor: pointer;
		fill: #3843ed;
	}
}
