.message {
	position: relative;

	&__icon {
		//height: 20px;
		//width: 20px;
	}

	&__span {
		background: #ff776f;
		border-radius: 50%;
		box-shadow: 0 0 0 2px #edf2ff;
		height: 6px;
		position: absolute;
		right: 0;
		top: 0;
		width: 6px;
		z-index: 5;
	}
}
