.tooltip-job-title {
	max-width: 294px;

	&__title {
		margin-bottom: 8px;
	}

	&__ul {
		margin: 8px 12px;
	}

	&__li {
		margin-bottom: 8px;
		list-style: decimal;
	}
}
