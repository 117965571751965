.sider {
	display: flex;
	flex-direction: column;
	max-width: 600px;
	min-height: 100vh;
	background:
		url('../../../../assets/image/bg-aside.svg') no-repeat bottom,
		linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);
	background-size: contain;
}

.form-sider {
	display: flex;
	flex-direction: column;
	width: 480px;
	padding-top: 48px;
	padding-left: 64px;
	padding-right: 42px;
	background: linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);

	@media screen and (width <= 768px) {
		width: auto;
		max-width: 480px;
		flex: 1;
		padding-left: 24px;
		padding-right: 24px;
	}
}

.form-sider.background {
	background:
		url('../../../../assets/image/bg-aside.svg') no-repeat bottom,
		linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);
	background-size: contain;
}
