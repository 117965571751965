@import '../../../assets/helpers/variables';

.login-options {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	&__title {
		position: relative;
		text-align: center;
		color: $colorGreyMain;
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 32px;
		background-color: $colorWhite;
		max-width: 336px;
		overflow: hidden;

		&::after,
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			width: 100px;
			height: 1px;
			background-color: $colorGreyLight;
		}

		&::after {
			left: 0;
			transform: translateX(-50%);
		}

		&::before {
			right: 0;
			transform: translateX(50%);
		}
	}

	&__list {
		display: flex;
	}

	&__card {
		margin-right: 16px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $colorGreyLight;
		border-radius: 8px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		width: 104px;

		@media screen and (width <= 768px) {
			width: 100%;
		}

		&:hover {
			box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
			border-color: transparent;
		}

		&:nth-last-child(1) {
			margin: 0;
		}
	}

	&__icon {
		display: block;
		width: 24px;
		height: 24px;
	}
}
