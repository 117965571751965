.social-list {
	display: flex;
	grid-gap: 3px;

	&__item {
		a {
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
