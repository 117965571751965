%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

%desc {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #303842;
}

.modal-edit-profile {
	display: flex;

	.ant-modal-content {
		border-radius: 8px;
		box-shadow:
			0 6px 6px rgb(0 0 0 / 8%),
			0 0 80px rgb(0 0 0 / 32%);
	}

	&__header {
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		@extend %title;
	}

	&__desc {
		@extend %desc;
	}
}
