.aside-template {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	height: 100%;

	&__body {
		padding: 48px 24px 24px;
		display: flex;
		flex: 1;

		@media screen and (width <= 768px) {
			padding: 5px;
		}
	}

	&__body-center {
		padding-top: 92px;
		width: 100%;
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
	}

	.sider {
		padding-top: 92px;
		padding-left: 64px;
		padding-right: 42px;

		@media screen and (width <= 768px) {
			padding-top: 50px;
			padding-left: 24px;
			padding-right: 24px;
		}
	}

	.dropdown {
		position: absolute;
		right: 0;
		z-index: 1;
		margin: 24px;
		width: 44px;
	}
}
