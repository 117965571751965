@import '../../../assets/helpers/variables';
@import '../../../assets/helpers/mixins';

.attachment {
	padding-bottom: 12px;
	display: flex;
	justify-content: space-between;

	&__body {
		display: flex;
		align-items: center;
	}

	&__name {
		margin: 0 8px 0 12px;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #20272e;
	}

	&__size {
		font-weight: 400;
		font-size: 12px;
		line-height: 24px;
		color: #6d7895;
	}

	&__nav {
		display: flex;
		align-items: center;

		a {
			display: flex;
			align-items: center;
		}

		svg {
			margin: 0 8px;
			fill: #d2d8e8;
		}

		svg:hover {
			cursor: pointer;
			fill: #6d6be5;
			transition: all 0.5s;
		}
	}
}
