%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

%desc {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #303842;
}

%item {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #6d7895;
}

.modal-status {
	display: flex;
	flex-direction: column;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__header {
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		margin-bottom: 12px;

		@extend %title;
	}

	&__desc {
		@extend %desc;

		text-align: center;
	}

	&__item {
		cursor: pointer;
		padding: 0 8px;
		background: #d2d8e8;
		border-radius: 20px;

		@extend %item;

		&-1:hover {
			color: #58c65c;
			background: #e3f6e3;
		}

		&-active-1 {
			color: #58c65c;
			background: #e3f6e3;
		}

		&-2:hover {
			color: #5e6bf6;
			background: #edf2ff;
		}

		&-active-2 {
			color: #5e6bf6;
			background: #edf2ff;
		}

		&-3:hover {
			color: #ffc864;
			background: #fff8e9;
		}

		&-active-3 {
			color: #ffc864;
			background: #fff8e9;
		}

		&-4:hover {
			color: #9f64ff;
			background: #efe9ff;
		}

		&-active-4 {
			color: #9f64ff;
			background: #efe9ff;
		}

		&-5:hover {
			color: #e964ff;
			background: #fce9ff;
		}

		&-active-5 {
			color: #e964ff;
			background: #fce9ff;
		}

		&-6:hover {
			color: #64b5ff;
			background: #e9fbff;
		}

		&-active-6 {
			color: #64b5ff;
			background: #e9fbff;
		}
	}

	&__body {
		margin: 24px 0 32px;
		max-width: 376px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		grid-gap: 12px;
	}

	&__footer {
		display: flex;
		justify-content: center;

		.btn-default {
			max-width: 216px;
		}
	}
}
