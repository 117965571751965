%date {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #8d97b0;
}

%text {
	font-weight: 500;
	font-size: 12px;
	line-height: 120%;
	color: #47525f;
}

.lc-messages-list {
	grid-gap: 12px;
	display: flex;
	flex-direction: column;

	&__item {
		padding: 12px;
		display: flex;
		flex-direction: column;
		background: #fff;
		border: 1px solid #cbd5e2;
		border-radius: 8px;

		&-body {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&-header {
			display: flex;
			justify-content: space-between;
		}

		&-profile {
			display: flex;

			.user-profile {
				padding: 0;
				margin: 0;

				&__block {
					display: flex;
					flex-direction: column;
				}

				&__position {
					font-weight: 500;
					font-size: 10px;
					line-height: 12px;
					color: #47525f;
				}
			}
		}

		&-date {
			@extend %date;
		}

		&-text {
			margin-top: 12px;

			@extend %text;
		}

		&-nav {
			grid-gap: 16px;
			display: flex;
			justify-content: flex-end;

			svg {
				fill: #d2d8e8;
				transition: all 0.5s;
			}

			svg:hover {
				cursor: pointer;
				fill: #3843ed;
				transition: all 0.5s;
			}
		}
	}

	&__item-focus {
		border: 2px solid #6d6be5;
	}

	&__textarea {
		margin-top: 12px;
		resize: none;
		height: 100%;
		border: none;
		outline: none;

		@extend %text;
	}
}
