%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

%desc {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #47525f;
}

.modal-confirm {
	display: flex;
	justify-content: center;
	align-items: center;

	.ant-modal-close {
		width: 24px;
		height: 24px;

		&:hover {
			background-color: transparent;
		}

		.anticon-close {
			svg {
				width: 14px;
				height: 14px;
			}
		}
	}

	&__decline {
		padding: 32px 64px;
	}

	&__header {
		margin-bottom: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__body {
		.form {
			//display: flex;
			//flex-direction: column;
			//align-items: center;

			button[type='submit'] {
				margin: 56px auto 12px;
				max-width: 216px;
			}
		}

		.form.code-form {
			width: 100%;
		}

		.btn-clasic,
		.btn-gray {
			margin: 0 auto;
			max-width: 216px;
		}
	}

	&__title {
		margin-bottom: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		@extend %title;
	}

	&__desc {
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;

		@extend %desc;
	}

	&__btns {
		margin: 0 auto;
		max-width: 216px;

		.btn-gray {
			margin-top: 12px;
		}
	}

	&__btns-decline {
		gap: 36px;
		display: flex;
		align-items: center;
		justify-content: flex-end !important;

		button {
			width: 116px;
		}
	}
}

.modal-confirm__verification {
	.ant-modal-content {
		width: 504px;
		height: 402px;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;

		.ant-modal-body {
			width: 376px;
			height: 290px;

			.code-form {
				max-width: 336px;
				margin-left: auto;
				margin-right: auto;

				button,
				button[type='submit'] {
					max-width: none;
				}

				button[type='submit'] {
					margin-top: 48px;
				}
			}

			.modal-confirm__desc {
				text-align: center;
				color: #47525f;
			}
		}
	}
}

.decline {
	margin-bottom: 24px;
}

.decline-desc {
	margin-bottom: 0;
	justify-content: start;
	color: var(--text-dark, #20272e);
	font-size: 12px;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	line-height: 24px;
}

.verification {
	height: 116px !important;
}

.modal-add-address,
.modal-add-department {
	.ant-modal-content {
		padding: 0;

		.ant-modal-body {
			display: flex;
		}
	}

	&__left {
		box-sizing: border-box;
		padding: 20px 24px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		width: 360px;
		min-height: 592px;
		background-image: url('../../../../assets/image/icons/table.svg'),
			linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);
		background-position: 100% 100%;
		background-repeat: no-repeat;
		background-size: contain;

		h2 {
			font-size: 24px;
			font-weight: 700;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: left;
			color: #fff;
			margin-bottom: 12px;
		}

		p {
			color: #edf2ff;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0;
			text-align: left;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}
	}

	.form-company-contacts {
		padding: 20px 24px;
		width: 576px;
		box-sizing: content-box;
		height: calc(100% - 40px);

		.form-footer_submit {
			max-width: 100%;
			justify-content: flex-end;

			.btn-transparent {
				color: #6d7895;
				width: auto;
				padding-left: 12px;
				padding-right: 12px;
				margin-right: 24px;
			}

			button[type='submit'] {
				padding-left: 24px;
				padding-right: 24px;
				max-width: none;
				width: auto;

				.btn-default__text {
					margin: auto;
				}
			}
		}
	}
}

.modal-add-address {
	&__left {
		background-image: url('../../../../assets/image/icons/location.svg'),
			linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);
		background-size: auto;
		background-position: 50% 90%;
	}
}
