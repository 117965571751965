@import '@assets/colors.scss';

%text {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: $grey2;
}

.companies-tabs {
	width: 100%;
	padding-bottom: 0;
	height: calc(100% - 104px);

	@media screen and (width <= 336px) {
		width: 100%;
	}

	&__title {
		display: flex;

		&-counter {
			margin: 0 8px;
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			color: white;
			background: #d2d8e8;
		}
	}

	.ant-tabs-tab-active &__title {
		&-counter {
			background: #4e5af2;
		}
	}

	.ant-tabs-nav-list {
		width: 100%;
	}

	.ant-tabs-tab {
		margin: 0;
		padding: 12px 36px;
		justify-content: center;

		@extend %text;
	}

	.ant-tabs-tab:hover {
		color: #3843ed;
		text-shadow: 0 0 0.25px currentcolor;
	}

	.ant-tabs-tab:hover &__title {
		&-counter {
			transition: all 0.3s;
			background: #4e5af2;
		}
	}

	.ant-tabs-nav {
		margin: 0 0 24px;
	}

	.ant-tabs-tab.ant-tabs-tab-active {
		font-weight: 500;
		color: #3843ed;

		.ant-tabs-tab-btn {
			color: #3843ed;
		}
	}

	.ant-tabs-ink-bar {
		height: 4px !important;
		background: $blue;
		border-radius: 4px 4px 0 0;
	}

	.ant-tabs-nav::before {
		border-bottom: 1px solid $grey;
	}

	.ant-tabs-content {
		outline: none;
		height: 100%;
		//padding: 24px;
		//min-height: 458px;
		//background: #fff;
		// box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
		// border-radius: 8px;

		.my-companies {
			padding: 24px;
		}
	}

	.padding-0 {
		padding: 0;
		outline: none;
	}

	/* TODO bug on resolution */

	.ant-tabs-nav-operations {
		display: none !important;
	}
}

.settings-tabs-empty {
	&__text {
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		color: #303842;
	}

	.ant-tabs-nav {
		margin: 24px 0;
	}

	.ant-tabs-content {
		outline: none;
		padding: 12px;
		min-height: 458px;
		display: flex;
		justify-content: center;
		background: transparent;
		box-shadow: none;
		border-radius: none;
	}
}
