%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

.ant-modal-root {
	.ant-modal-wrap {
		z-index: 1060;
	}
}

.ant-modal-root.modal-search__wrap {
	.ant-modal-wrap {
		overflow: hidden;

		.modal-search {
			min-width: 100vw !important;
			width: 100% !important;
			margin: auto !important;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			padding-bottom: 0 !important;

			.ant-modal,
			.ant-modal-content {
				padding: 24px;
				height: 100vh;
				width: 100vw;
				margin: 0;
				top: 0;
			}

			.ant-modal-body {
				height: calc(100vh - 50px);
			}

			.none {
				display: none;
			}

			.center {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 30px;

				> button {
					width: 184px;
				}
			}

			&__body {
				display: flex;
				height: 100%;
				flex-direction: column;
			}

			&__title {
				margin: 24px 0;
				display: flex;
				justify-content: center;

				@extend %title;
			}

			&__block-center {
				margin-bottom: 36px;
				display: flex;
				justify-content: center;
			}
		}

		.ant-pagination {
			z-index: 10;
			position: static;
			margin: 0;
			margin-left: auto;
			padding: 20px 0;
			right: 24px;
			bottom: 24px;
			margin-bottom: -72px;
		}
	}
}
