/* stylelint-disable selector-class-pattern */
.form.form-company-registration {
	width: 100%;
}

.form__body__text {
	background: #edf2ff;
	border-radius: 8px;
	padding: 24px;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0;
	text-align: left;
	margin-bottom: 24px;

	b {
		font-weight: 600;
	}
}

.form-company-contacts,
.form-company-update,
.form-company-registration {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (width <= 768px) {
		width: 100%;
	}

	.form__container {
		max-width: 576px;

		&-mini {
			max-width: 376px;
		}

		&.w-96 {
			width: 100%;
			max-width: 96px;
		}

		&.w-176 {
			width: 100%;
			max-width: 176px;
		}

		&.w-276 {
			width: 100%;
			max-width: 276px;
		}

		&.w-376 {
			width: 100%;
			max-width: 376px;
		}

		&.w-456 {
			width: 100%;
			max-width: 456px;
		}

		.companies-list__status {
			margin: 4px 0 0;
			max-width: 120px;
		}

		.ant-upload-wrapper {
			display: block;
			margin-bottom: 12px;

			&.uploader-icon-zone {
				margin-bottom: 0;
			}
		}

		.input-file {
			margin-bottom: 12px;
		}

		.edit-profile-form__social-select {
			.input-default__input {
				input {
					padding: 8px 8px 8px 16px;

					&.isIcon {
						padding: 8px 32px 8px 16px;
					}
				}

				.input-default__icons {
					right: 8px;
					display: flex;

					svg {
						margin-right: 8px;
						cursor: pointer;

						&:hover path {
							fill: #3843ed;
							transition: all 0.2s;
						}
					}
				}
			}
		}
	}

	.form__sub-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&.hidden {
			display: none;
		}
	}

	.form__sub-title {
		color: #6d6be5;
		font-size: 14px;
		line-height: 24px;
		font-weight: 600;
	}

	.form__container-columns {
		max-width: 576px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	.form__container-columns > .input-default {
		flex-grow: 1;
		max-width: 176px;
	}

	button[type='submit'] {
		max-width: 90px;
	}
}

.form-company-update .form-company-registration {
	height: auto;
}
