.collapsed {
	background-color: #fff;
	bottom: 0;
	cursor: pointer;
	padding: 24px 0;
	position: sticky;
	width: 100%;

	&__arrow {
		height: 24px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(-100%, -50%);
		width: 24px;
	}

	&.is_collapsed {
		.collapsed__arrow {
			right: 50%;
			transform: translate(50%, -50%) rotate(180deg);
		}
	}
}
