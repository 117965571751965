%text {
	font-family: Montserrat, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #fff;
}

.btn-default {
	cursor: pointer;
	transition: background 300ms linear;
	outline: none;
	border: none;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #4e5af2;
	border-radius: 20px;
	box-shadow: 0 0 0;

	&__text {
		margin: 0 11px;
	}

	@extend %text;
}

.btn-default:hover {
	transition:
		background 300ms linear,
		box-shadow 300ms linear;
	background: #3843ed;
	box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
}

.btn-default:active {
	transition:
		background 300ms linear,
		box-shadow 300ms linear;
	background: #5e6bf6;
}

.btn-default:disabled {
	background: #dcdefc;
	box-shadow: none;
	color: #8d97b0;
	cursor: default;
}

.btn-default.btn-white {
	transition: 200ms linear;
	background-color: #fff;
	color: #4e5af2;
}

.btn-default.btn-white:hover {
	transition: 200ms linear;
	background-color: #4e5af2;
	color: #fff;
}

.btn-default.btn-clasic {
	transition: 200ms linear;
	background-color: transparent;
	color: #dcdefc;
	border: 1px solid #dcdefc;
}

.btn-default.btn-gray {
	transition: 200ms linear;
	background-color: white;
	color: #8d97b0;
	border: 1px solid hsl(236, 84%, 93%);
}

.btn-default.btn-transparent {
	background-color: transparent;
}

.btn-default.btn-transparent:hover {
	box-shadow: none;
}

.btn-default.btn-gray:hover {
	transition: 200ms linear;
	color: #47525f;
	box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
	border: 1px solid #3843ed;
}

.btn-clasic:hover {
	transition: 200ms linear;
	border: 1px solid #3843ed;
	color: #fff;
	box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
}

.btn-clasic:active {
	transition: background 300ms linear;
	background: #edf2ff;
	color: #3843ed;
}

.btn-default.btn-trans {
	background: #fff;
	border: 1px solid #cbd5e2;
	color: #6d7895;
}

.btn-default.btn-default__clean {
	padding: 12px;
	background: none;
	border: none;
	color: #4e5af2;

	&:disabled {
		color: #6d7895;
		cursor: default;
	}

	&:hover {
		box-shadow: none;
	}

	.btn-default__text {
		margin: 0;
	}
}
