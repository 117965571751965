/* stylelint-disable value-no-vendor-prefix */
.set-company-admin {
	.ant-form-item-explain {
		position: relative;
	}

	.ant-form-item-explain-error {
		transition: all 1s;
		top: 3px;
		position: absolute;
		line-height: 120%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	/* TODO hover effect for long error message */

	/* .ant-form-item-explain-error:hover {
    transition: all 1s;
    background: white;
    z-index: 1;
    overflow: auto;
    display: flex;
  } */
}
