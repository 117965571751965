.companies {
	width: 576px;
}

.dropdown-search {
	position: absolute;
	top: 70px;
}

.drop-container {
	max-height: 144px;
	overflow: auto;
}

.ant-dropdown.dropdown-companies-wrap {
	max-width: 576px;

	.ant-dropdown-menu {
		max-height: 408px;
		overflow-x: auto;
		padding: 0;
		border-top: 8px solid white;
		border-bottom: 8px solid white;

		.ant-dropdown-menu-item {
			padding: 12px 16px;
			display: block;
			width: 100%;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			border-radius: 0;

			.dropdown-overlay__item {
				line-height: 24px;
				height: 24px;
				font-size: 14px;
				color: #20272e;

				.dropdown-overlay__text {
					&.dropdown-overlay__text-length {
						color: #303842;
						font-weight: 400;
						display: flex;
						align-items: center;
					}

					span {
						font-weight: 600;
					}
				}

				.dropdown-overlay__text-secondary {
					font-weight: 400;
					color: #8d97b0;
					white-space: nowrap;
					overflow: hidden;
					max-width: 100%;
					text-overflow: ellipsis;

					span {
						font-weight: 500;
					}
				}
			}

			&:last-of-type {
				background: white;
				position: sticky;
				bottom: 0;
				left: 0;
				right: 0;

				&:hover {
					background: #edf2ff;
				}

				div {
					display: flex;
					line-height: 24px;
					height: 24px;

					span {
						padding-right: 8px;
						font-size: 14px;
					}
				}
			}

			.dropdown-overlay__item-content {
				height: 48px;
				display: flex;
				flex-direction: column;
			}

			.search-column-sorter {
				display: inline-flex;
				margin-inline-start: 8px;
				color: rgba(0, 0, 0, 29%);
				font-size: 0;
				transition: color 0.3s;
				height: 12px;

				.search-sorter-inner {
					display: flex;
					flex-direction: column;

					.anticon {
						font-size: 9px;
						height: 6px;
					}
				}
			}
		}
	}
}
