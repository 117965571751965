/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-duplicate-selectors */
%input {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #20272e !important;
}

%select {
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;
	color: #303842;
}

body .ant-select .ant-select-arrow {
	color: #4e5af2;
}

.form-select {
	.ant-select-selector {
		cursor: pointer !important;
		height: 40px !important;
		padding: 0 16px !important;
		background: #fff !important;
		border: 1px solid #cbd5e2 !important;
		box-sizing: border-box !important;
		border-radius: 4px !important;

		@media not all and (resolution >= 0.001dpcm) {
			@supports (-webkit-appearance: none) {
				input {
					line-height: 40px;
				}
			}
		}
	}

	.ant-select-selector:hover {
		border: 1px solid #4e5af2 !important;
	}

	.ant-select-selection-item {
		@extend %input;

		line-height: 40px !important;
	}

	.ant-select-selection-placeholder {
		display: flex;
		align-items: center;

		@extend %placeholder;
	}

	&__error {
		.ant-select-selector {
			border: 2px solid #ff776f !important;
		}
	}
}

.form-select.ant-select-open {
	.ant-select-arrow {
		transform: rotate(180deg) !important;
	}
}

.form-select.ant-select-open {
	.ant-select-selector {
		color: #20272e;
		border: 2px solid #3843ed !important;
	}
}

.form-select.ant-select-open {
	.ant-select-selector {
		color: #20272e;
		border: 2px solid #3843ed !important;
	}
}

.form-dropdown {
	&.select-custom-dropdown {
		padding-bottom: 0;
		z-index: 999;
	}

	background-color: #fff;
	box-shadow:
		0 6px 6px rgb(0 0 0 / 8%),
		0 0 80px rgb(0 0 0 / 32%);
	border-radius: 8px;
	padding: 4px 0;

	.ant-select-item {
		height: 48px;
		display: flex;
		align-items: center;

		@extend %select;

		.option-content {
			font-size: 14px;
			font-weight: 500;
			line-height: 14px;
			letter-spacing: 0;
			text-align: left;
		}
	}

	.ant-select-item-option-selected {
		background-color: #fff;
	}

	.ant-select-item-option-active {
		background: #edf2ff;
	}

	&.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: transparent;
	}

	&.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
		background-color: #f5f5f5;
	}
}

.placeholder-select {
	height: 40px;
	left: 16px;
	right: 16px;
	overflow: hidden;
	top: 20px;
	position: absolute;
	font-weight: 400;
	font-size: 12px;
	color: #8d97b0;
	white-space: nowrap;
	line-height: 40px;
	inset-inline-start: 16px;
	inset-inline-end: 28px;
	transform: translateY(-50%);
	transition: all 0.3s;
	z-index: 0;
}

.input-select-multiple {
	.ant-select-multiple {
		//height: auto !important;
		z-index: 1;

		.ant-select-arrow {
			top: 21px;
		}

		.ant-select-selection-placeholder {
			top: 20px;
		}

		.ant-select-selection-item {
			background: #8d8bed;
			font-size: 12px;
			font-weight: 500;
			line-height: 24px !important;
			letter-spacing: 0;
			text-align: left;
			padding: 0 4px 0 8px;
			border-width: 0;
			border-radius: 20px;
			margin: 0 12px 0 0;

			.ant-select-selection-item-content {
				line-height: 24px;
				color: #fff;
			}

			.ant-select-selection-item-remove {
				.anticon-close {
					width: 12px;
					height: 12px;
					background: #fff;
					border-radius: 50%;
				}
			}
		}

		.ant-select-selector {
			padding-left: 0 !important;
			background: none !important;
			border: none !important;
			//height: auto !important;
		}

		.ant-select-selection-search {
			height: 0;
		}

		&:not(.ant-select-empty)::before {
			cursor: pointer !important;
			height: 40px !important;
			padding: 0 16px !important;
			border: 1px solid #cbd5e2 !important;
			box-sizing: border-box !important;
			border-radius: 4px !important;
			content: '';
			position: absolute;
			display: block;
			//z-index: 1;
			right: 0;
			left: 0;
		}

		&:not(.ant-select-empty):hover::before {
			border-color: #4e5af2 !important;
		}

		&.ant-select-open::before {
			border-color: #3843ed !important;
			border-width: 2px !important;
		}

		.ant-select-selection-overflow {
			margin-top: 40px;

			.ant-select-selection-overflow-item {
				margin-top: 12px;

				&.ant-select-selection-overflow-item-suffix {
					margin-top: 0;
				}
			}
		}

		&.ant-select-empty {
			.ant-select-selection-overflow {
				margin-top: 0;
			}
		}
	}
}

.input-select-custom {
	.ant-select:not(.ant-select-empty) {
		.ant-select-selection-overflow {
			height: 0;
			max-height: 0;
			overflow: hidden;
		}
	}

	.ant-select-empty {
		width: 100%;
	}
}

.select-custom {
	width: 100%;

	&__content {
		// height: 192px;
		// max-height: 192px;
		// overflow: auto;
		.rc-virtual-list-holder {
			max-height: 192px !important;
			height: auto;

			.rc-virtual-list-holder-inner {
				width: 100%;
				flex-wrap: nowrap;
				flex-direction: column;

				.ant-select-item,
				&-item {
					padding: 12px 16px;

					&.ant-select-item-option-selected {
						background: transparent;
						color: #605dec;
						font-weight: 500;

						.ant-select-item-option-state {
							background: transparent;
						}
					}

					&::after {
						content: '';
					}

					.ant-checkbox-group {
						display: flex;
						align-content: center;
						align-items: center;

						.ant-checkbox-inner {
							margin-right: 12px;
							box-sizing: border-box;
							position: relative;
							top: 0;
							inset-inline-start: 0;
							display: block;
							direction: ltr;
							background-color: #fff;
							border: 2px solid #d2d8e8;
							border-radius: 4px;
							border-collapse: separate;
							transition: all 0.3s;
							width: 20px;
							height: 20px;

							&::after {
								box-sizing: border-box;
								position: absolute;
								top: 50%;
								inset-inline-start: 26.5%;
								display: table;
								width: 6px;
								height: 10px;
								border: 2px solid #fff;
								border-top: 0;
								border-inline-start: 0;
								transform: rotate(45deg) scale(0) translate(-50%, -50%);
								opacity: 0;
								content: '';
								transition:
									all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
									opacity 0.1s;
							}
						}
					}
				}

				.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
					background: #edf2ff;
					color: #303842;
				}

				.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
					.ant-select-item:hover {
					.ant-checkbox-inner {
						border-color: #71d475;
					}
				}

				.ant-select-item.ant-select-item-option-selected {
					.ant-select-item-option-state {
						display: none;
					}

					.ant-checkbox-group {
						.ant-checkbox-inner {
							background-color: #71d475;
							border-color: #71d475;

							&::after {
								opacity: 1;
								transform: rotate(45deg) scale(1) translate(-50%, -50%);
								transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
							}
						}
					}
				}
			}
		}
	}

	.company_empty__button {
		padding: 12px 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;

		&:hover {
			background: #edf2ff;
		}

		&:active {
			background: #4e5af2;

			.company_empty__button__text {
				color: #fff;
			}
		}

		&__icon {
			height: 32px;
			width: 32px;
			border-radius: 32px;
			background: #fff;
			box-shadow: 0 1px 10px 0 #00000029;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 10px;
				height: 10px;
			}
		}

		&__text {
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0;
			text-align: left;
			color: #303842;
		}
	}

	&__button-bottom {
		display: flex;
		border-top: 1px solid #edf2ff;

		span {
			cursor: pointer;
			padding: 12px 16px;
			width: 50%;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0;
			text-align: center;

			&.select-custom__submit {
				background: #edf2ff;
			}

			&:hover {
				color: #4e5af2;
			}
		}
	}
}

.ant-select-item-option-content {
	.checkbox-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.ant-checkbox-group,
	.option-content {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;

		.ant-checkbox-label {
			font-size: 14px;
			font-weight: 500;
			line-height: 14px;
			letter-spacing: 0;
			text-align: left;
			color: #303842;
		}

		.remove-icon {
			display: flex;
			align-items: center;

			svg {
				margin: 0;
				fill: #d2d8e8;

				&:hover {
					cursor: pointer;
					fill: #6d6be5;
					transition: all 0.5s;
				}
			}
		}
	}
}
