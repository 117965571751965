%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}
%desc {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	text-align: center;
	color: #47525f;
}

.form-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__title {
		margin-bottom: 16px;

		@extend %title;
	}

	&__desc {
		margin-bottom: 32px;

		@extend %desc;
	}
}

.simple-header {
	position: relative;
	max-width: 936px;
	display: flex;
	justify-content: space-between;

	&__text {
		display: flex;
		flex-direction: column;
	}

	&__title {
		margin-bottom: 24px;

		@extend %title;

		letter-spacing: 0.02em;
	}

	&__desc {
		margin-bottom: 12px;

		@extend %title;

		font-size: 12px;
	}

	&__close {
		position: absolute;
		top: -32px;
		right: -6px;

		svg {
			cursor: pointer;
			width: 14px;
			height: 14px;
		}
	}
}

.simple-header.archive .simple-header__desc {
	margin-bottom: 0;
}
