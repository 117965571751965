/* stylelint-disable selector-class-pattern */

%link {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #4e5af2;
}

.form.user-registration-form {
	width: 100%;
}

.user-registration-form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (width <= 768px) {
		width: 100%;
	}

	.form__container {
		position: relative;
		max-width: 576px;

		.confirm-phone {
			cursor: pointer;
			position: absolute;
			top: 32px;
			right: 8px;

			@extend %link;
		}
	}

	.form__container-columns {
		max-width: 576px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	.form__container-columns > .input-default {
		flex-grow: 1;
		max-width: 176px;
	}

	button[type='submit'] {
		max-width: 90px;
	}
}
