@import './animations.scss';

%title {
	font-weight: 600;
	font-size: 36px;
	line-height: 40px;
	color: #fff;
}

%desc {
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	color: #edf2ff;
}

.page-404 {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	background: linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);

	&__child {
		margin: 90px;
		max-width: 450px;
		display: flex;
		flex-direction: column;

		span {
			margin: 24px 0;

			@extend %title;

			@media screen and (width <= 768px) {
				font-size: 36px;
			}
		}

		p {
			margin-bottom: 48px;
			max-width: 447px;
			overflow-wrap: break-word;

			@extend %desc;
		}

		.btn-default {
			max-width: 336px;
			min-width: 200px;
		}
	}

	&__body {
		margin-top: 90px;
		width: 700px;
		height: 700px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		//flex: 1;

		@media screen and (width <= 768px) {
			width: 100%;
			overflow: hidden;
		}
	}

	&__element {
		z-index: 3;
		display: flex;
		position: absolute;
	}

	&__background-1 {
		z-index: 1;
	}

	&__background-2 {
		z-index: 2;
	}

	&__rocket {
		z-index: 3;
		rotate: (-5deg);
		animation: rocket 5s infinite;
	}

	&__planet-1 {
		z-index: 4;
		top: 490px;
		left: 25px;
		rotate: (0);
		animation: planet-1 5s infinite;
	}

	&__planet-2 {
		z-index: 4;
		top: 147px;
		right: -15px;
		rotate: (0);
		animation: planet-1 5s infinite;
	}

	&__large-star-1 {
		top: 212px;
		left: 11px;
		transform: translate(0, 0);
		animation: large-star-1 5s infinite;
	}

	&__large-star-2 {
		top: 160px;
		right: 207px;
		transform: translate(0, 0);
		animation: large-star-2 5s infinite;
	}

	&__large-star-3 {
		bottom: 240px;
		right: 0;
		transform: translate(0, 0);
		animation: large-star-3 5s infinite;
	}

	&__large-star-4 {
		bottom: 126px;
		left: 216px;
		transform: translate(0, 0);
		animation: large-star-4 5s infinite;
	}

	&__small-star-1 {
		//top: 188px;
		//left: 157px;
		transform: translate(0, 0);
		animation: orbita-1 25s linear infinite;
	}

	&__small-star-2 {
		top: 139px;
		right: 5px;
	}

	&__small-star-3 {
		//top: 279px;
		//right: 111px;
		transform: translate(0, 0);
		animation: orbita-3 40s linear infinite;
	}

	&__small-star-4 {
		//bottom: 177px;
		//right: 179px;
		transform: translate(0, 0);
		animation: orbita-4 15s linear infinite;
	}

	&__large-dot-1 {
		//bottom: 240px;
		//left: 37px;
		transform: translate(0, 0);
		animation: orbita-1 15s linear infinite;
	}

	&__large-dot-2 {
		//top: 340px;
		//right: 4px;
		transform: translate(0, 0);
		animation: orbita-2 22s linear infinite;
	}

	&__small-dot-1 {
		inset: 27.59% 20.01% 71.98% 79.56%;
	}

	&__small-dot-2 {
		inset: 42.82% 81.66% 56.76% 17.92%;
	}

	&__small-dot-3 {
		inset: 57.51% 14.16% 42.06% 85.42%;
	}

	&__small-dot-4 {
		inset: 77.57% 46.08% 22% 53.5%;
	}

	&__small-dot-5 {
		inset: 81.96% 90.27% 17.62% 9.31%;
	}

	&__small-dot-6 {
		inset: 81.54% 17.07% 18.04% 82.49%;
	}
}
