.info-block {
	display: flex;

	&__type {
		width: 40px;
		height: 40px;
		border-radius: 8px;

		&-await {
			background:
				url('../../../assets/image/icons/events/await.svg') no-repeat center,
				#fff8e9;
		}

		&-new {
			background:
				url('../../../assets/image/icons/events/new.svg') no-repeat center,
				#edf2ff;
		}

		&-birth {
			background:
				url('../../../assets/image/icons/events/birth.svg') no-repeat center,
				#ffe4e2;
		}

		&-doc {
			background:
				url('../../../assets/image/icons/events/doc.svg') no-repeat center,
				#e3f6e3;
		}

		&-social {
			background:
				url('../../../assets/image/icons/events/social.svg') no-repeat center,
				#edf2ff;
		}

		&-validation {
			background:
				url('../../../assets/image/icons/events/validation.svg') no-repeat center,
				#fff8e9;
		}
	}

	&__item {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 8px 0;
		background-color: #fff;
		border-radius: 8px;
		font-size: 12px;
		line-height: 16px;
		cursor: pointer;

		&._warning {
			.info-blocks__message {
				//max-width: 70%;
			}
		}
	}

	&__date {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 40px;
		height: 40px;
		background: #edf2ff;
		border-radius: 8px;
		margin-right: 8px;
	}

	&__day {
		font-weight: 600;
		color: #6d7895;
	}

	&__month {
		font-size: 9px;
		color: #6d7895;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__message {
		padding: 2px 0 2px 8px;
		display: flex;
		flex-direction: column;
		max-width: calc(100% - 40px);
		justify-content: space-between;
		height: 100%;
	}

	&__link {
		height: 16px;
		display: flex;
		align-items: center;

		svg {
			font-size: 12px;
			fill: #d2d8e8;
			height: 16px;
			width: 16px;
			margin-left: 2px;

			&:hover {
				transition: all 0.2s;
				fill: #3843ed;
			}
		}
	}

	&__title,
	&__subtitle {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		height: 14px;
		font-size: 12px;
		line-height: 14.4px;
	}

	&__title {
		color: #303842;
		font-weight: 600;
	}

	&__subtitle {
		color: #6d7895;
	}

	&__img-warning {
		display: block;
		width: 24px;
		height: 24px;
		margin: auto;
	}

	&__counter {
		margin-left: 8px;
		min-width: 40px;
		background: #8d8bed;
		border-radius: 8px;
		font-weight: 600;
		font-size: 18px;
		line-height: 120%;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		color: #fff;
	}
}
