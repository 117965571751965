.search-form {
	padding: 10px 16px;
	max-width: 576px;
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 50px;
	border: 1px solid transparent;
	box-shadow: 0 3px 3px rgb(0 0 0 / 8%);

	&__button {
		align-items: center;
		background: transparent;
		border: none;
		display: flex;
		margin-right: 10px;
		max-width: 20px;
		min-height: 20px;
	}

	&__icon {
		background: transparent;
		height: 20px;
		width: 20px;
	}

	&__close {
		cursor: default;
		opacity: 0;
		transition-property: opacity;
		transition-duration: 0.5s;
		transition-delay: 0.5s;
	}

	&__input {
		background: transparent;
		border: none;
		color: #6d7895;
		font-size: 12px;
		font-weight: 500;
		line-height: 24px;
		outline: none;
		padding: 0 15px 0 0;
		width: 100%;

		&::placeholder {
			opacity: 0.8;
		}

		&:focus + .search-form__close {
			cursor: pointer;
			opacity: 1;
		}
	}

	&:hover .search-form__close {
		cursor: pointer;
		opacity: 1;
	}
}

.search-form:hover {
	border: 1px solid #3843ed;
}

.search-form:has(input:focus) {
	border: 2px solid #3843ed;
}
