.sort-companies {
	display: flex;
	flex-direction: column;

	&__item {
		cursor: pointer;
		margin-bottom: 12px;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.02em;
		color: #a09ef2;

		&-active {
			font-weight: 600;
			font-size: 16px;
			color: #fff;
		}
	}
}
