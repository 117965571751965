.file-container {
	.ant-modal {
		height: fill-available;
		max-width: 100vw;
	}

	.ant-modal-content {
		width: 100vw;
		height: 100vh;
		top: 0;
		overflow: auto;
		background: #dcdefc;
	}

	.ant-modal-centered::before {
		content: unset;
	}

	.ant-modal-footer {
		display: none;
	}
}

.title {
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 0;
	text-align: left;
	color: #20272e;
}

.headerModal {
	padding: 24px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 24px;

	&_start {
		display: flex;
		gap: 12px;
		align-items: center;
	}

	&_end {
		display: flex;
		gap: 30px;
	}

	svg {
		fill: #6d6be5;
	}

	svg:hover {
		cursor: pointer;
		fill: #3843ed;
	}
}

.carousel-root {
	display: flex;
	max-height: 90vh;
	justify-content: center;
	align-items: center;
	padding-left: 24px;
	padding-right: 24px;

	.carousel {
		width: 100%;
		height: 80vh;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 50px;

		.slider-wrapper {
			&.axis-horizontal {
				max-height: 100%;
				height: 100%;

				.slider {
					max-height: 100%;
					height: 100%;

					.slide {
						display: flex;
						max-height: 100%;
						height: 100%;
					}

					li {
						max-height: 100%;
						position: relative;

						img {
							display: block;
							margin: auto;
						}
					}
				}
			}
		}
	}
}

.view-file-container {
	width: 100vw;
	height: 100vh;
	background: #dcdefc;
}

.percent {
	white-space: nowrap;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0;
	text-align: center;
	color: rgb(71 82 95 / 100%);
	margin: 0 8.5px;
}

.img-zoom-wrapper {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: auto;
		margin: auto;
		display: block;
	}

	.btn-wrap-zoom {
		display: flex;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 8%);
		position: fixed;
		bottom: 10px;
		padding: 12px;
		border-radius: 8px;
		background: white;
		left: auto;
		right: auto;
		margin: auto;
		max-height: 100%;
		min-width: 120px;

		.btn-zoom {
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 8%);
			cursor: pointer;
			border: none;
			width: 24px;
			height: 24px;
			border-radius: 8px;
			color: rgb(141 139 237 / 100%);
			background-color: white;
			display: flex;
			align-content: center;
			justify-content: space-around;
			align-items: center;
			cursor: pointer;

			svg {
				width: auto;
				margin: auto;
				display: block;

				path {
					transition: fill 0.3s;
				}
			}

			&:active svg path,
			&:hover svg path {
				fill: #00f;
			}
		}
	}
}

.ant-modal.modal-file {
	max-width: 100% !important;
	width: 100% !important;
	resize: 'none';
	overflow: 'clip';
	padding: 0;
	border-radius: 0;

	.ant-modal-content {
		background: none;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		padding: 0;
	}
}
