%title {
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	color: #20272e;
}

.lc-verification-company-container {
	min-height: 100vh;
	width: 100%;
	display: flex;
	grid-gap: 0;

	&__title {
		padding: 0 24px 24px 0;

		@extend %title;
	}

	&__list {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: #fff;
		width: 816px;
		max-width: 816px;
	}

	&__body {
		display: flex;
		flex-direction: column;
		padding-top: 48px;
		padding-left: 48px;
		height: 100vh;

		.ant-tabs-content-holder {
			padding-right: 24px;
		}
	}

	&__info {
		background: #edf2ff;
		width: 384px;
		min-width: 384px;
		max-width: 384px;
		padding: 24px;
	}

	&__footer {
		padding: 24px;
		display: flex;
		position: absolute;
		bottom: 0;
	}
}

.ant-modal-centered {
	overflow: clip !important;
}

.modal-lc-company {
	right: -15px;

	.ant-modal-close {
		left: 12px;
		top: 12px;
		width: 24px;
		height: 24px;

		&:focus,
		&:hover {
			background: none;
			color: #4e5af2;
			text-decoration: none;

			svg {
				fill: #4e5af2;
			}
		}

		.ant-modal-close-icon {
			font-size: 16px;
		}
	}

	padding-bottom: 0 !important;
	text-align: left;
	vertical-align: middle;
	width: 100% !important;
	overflow: clip !important;

	.ant-modal-body {
		padding: 0 !important;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		width: 100%;
		overflow: clip !important;
		margin-right: -15px;
	}

	.ant-modal-content {
		max-width: 1200px;
		overflow: clip !important;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding: 0;
		margin-left: auto;
		margin-right: 0;
	}

	.ant-modal-wrap .ant-modal-centered {
		overflow: clip !important;

		.ant-modal-centered .ant-modal {
			top: 0 !important;
			left: 262px !important;
			position: absolute !important;
			display: block !important;
			padding-bottom: 0;
			text-align: left;
			vertical-align: middle;
		}

		.ant-modal .modal-lc-company {
			top: 0;
			position: fixed;
			left: 255px !important;
			display: inline-block;
			padding-bottom: 0;
			text-align: left;
			vertical-align: middle;
			width: 100% !important;
			overflow: clip !important;
		}
	}
}

.ant-modal-wrap ant-modal-centered {
	overflow: clip !important;
}
