/* stylelint-disable selector-class-pattern */
.form.reset-form {
	width: 336px;

	.form__container-links {
		top: 64px;
		text-align: right;
	}

	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		margin: 32px 0;
	}
}
