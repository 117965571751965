%link {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #4e5af2;
}

%label {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #20272e;
}

%btn-text {
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #6d7895;
}

%remove {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #47525f;
}

.add-social-form {
	width: 100%;

	&__select {
		max-width: 216px;
	}

	&__link {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&__remove {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		span {
			margin-left: 6px;

			@extend %remove;
		}
	}

	&__container {
		margin-bottom: 32px;
		padding: 0 64px;
		height: 316px;
		overflow: auto;
	}

	&__container-label {
		margin-bottom: 12px;
		display: flex;
		align-items: center;

		@extend %label;
	}

	&__row {
		width: 100%;
		display: flex;
		//
		grid-gap: 24px;

		@media screen and (width <= 768px) {
			flex-wrap: wrap;
			grid-gap: 0;
		}
	}

	.input-default,
	&__block {
		width: 100%;
	}

	.input-default {
		&__icons {
			right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__block {
		position: relative;

		.confirm-phone {
			cursor: pointer;
			position: absolute;
			top: 32px;
			right: 8px;

			@extend %link;
		}
	}

	&__footer {
		padding: 0 64px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		grid-gap: 24px;

		.btn-default {
			max-width: 116px;
		}
	}

	&__btn-text {
		@extend %btn-text;
	}
}
