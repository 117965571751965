/* stylelint-disable value-no-vendor-prefix */
%title {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #20272e;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

%role {
	font-weight: 400;
	font-size: 12px;
	line-height: 100%;
	color: #6d7895;
}

%status {
	font-weight: 500;
	font-size: 10px;
	line-height: 24px;
	letter-spacing: 0.15px;
}

.companies-list {
	display: flex;
	flex-direction: column;

	&__item {
		opacity: 0;
		position: relative;
		padding: 8px 3px 8px 0;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #e4e4ef;

		&-loader {
			position: absolute;
			top: 12px;
			right: -25px;
			width: 18px;
			height: 18px;
		}
	}

	&__item.animation-opacity {
		opacity: 1;
	}

	&__name {
		display: flex;
		flex-direction: column;
	}

	&__title {
		cursor: pointer;

		@extend %title;
	}

	&__role {
		margin-top: 4px;

		@extend %role;
	}

	&__nav {
		display: flex;
		align-items: center;

		svg {
			cursor: pointer;
		}
	}

	&__restore {
		color: #4e5af2;
		font-size: 10px;
		font-weight: 600;
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			text-decoration: none;
		}
	}

	&__status {
		min-width: 120px;
		padding: 0 8px;
		margin: 0 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 20px;

		@extend %status;

		&-1 {
			background: #edf2ff;
			color: #5e6bf6;
		}

		&-2 {
			background: #fff4e4;
			color: #e99518;
		}

		&-3 {
			background: #ffe4e2;
			color: #ff776f;
		}

		&-4 {
			background: #e3f6e3;
			color: #58c65c;
		}

		&-5 {
			background: #d6d7d9;
			color: #6d7895;
		}
	}
}
