@import '@assets/colors.scss';

%text {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: $grey2;
}

.auth-tabs {
	width: 336px;

	@media screen and (width <= 336px) {
		width: 100%;
	}

	.ant-tabs-nav-list {
		width: 100%;
	}

	.ant-tabs-tab {
		width: 100%;
		justify-content: center;

		@extend %text;
	}

	.ant-tabs-content {
		outline: none;
	}

	.ant-tabs-nav {
		margin: 0 0 58px;
	}

	.ant-tabs-tab-active {
		font-weight: 600;
		color: $blue;
	}

	.ant-tabs-ink-bar {
		height: 4px !important;
		background: $blue;
		border-radius: 4px 4px 0 0;
	}

	.ant-tabs-nav::before {
		border-bottom: 1px solid $grey;
	}
}
