@import 'helpers/variables';
@import 'helpers/mixins';
@import 'helpers/animations';
@import 'helpers/fonts';
@import 'helpers/null';
@import 'helpers/scroll-bar';
@import 'helpers/print';

// ui
//@import "ui/ant";
//@import "ui";
@import 'form';
@import 'spin';
//@import "sider";
@import 'user';
//@import "info-blocks";
@import 'select';
@import 'uploader';
//@import "attachment";
@import 'pages/authentication';
@import 'pages/new-company';
// @import "success";
@import 'benefits';
@import 'header';
@import 'table';
// @import "modals";
@import 'details';
@import 'preview';
@import 'tooltip';
@import 'notification';
@import 'loaders';

html {
	min-height: 100vh;
	margin: auto;
	height: auto;
}

body {
	font-family: $fontFamily;
	min-height: 100vh;
	margin: auto;
	height: auto;

	[class^='ant-breadcrumb'],
	[class*=' ant-breadcrumb'],
	[class^='ant-checkbox'],
	[class*=' ant-checkbox'],
	[class^='ant-select'],
	[class*=' ant-select'] {
		font-family: $fontFamily;
	}

	div {
		letter-spacing: 0;
	}
}

#root {
	min-height: 100vh;
	margin: auto;
	height: auto;
	width: 100%;
}

.wrapper {
	display: flex;
}

.link {
	&:hover {
		color: #6d6be5;
	}
}
