.advance-search-form {
	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		width: 184px;
		height: 40px;
		margin: 0 auto;
		margin-bottom: 48px;
	}

	.column-form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
		width: 576px;
	}

	.forms {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		padding: 0;
		gap: 48px;
	}

	.date-range {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		gap: 24px;
		padding: 0;
		width: 576px;
	}

	.date-input {
		width: 276px;
	}
}
