.auth-form {
	width: 336px;

	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		margin: 20px 0 32px;
	}
}

/* .test__mask::before {
  content: "";
  position: absolute;
  border: 1px dashed #7b61ff;
  border-radius: 5px;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
} */
