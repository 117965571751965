.input-default.input-default__switch-wrap {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 12px;

	.ant-switch-wrap {
		display: flex;
		gap: 8px;

		.ant-switch-label {
			font-size: 10px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0;
			text-align: left;
			color: #8d97b0;
			display: flex;
		}
	}

	.ant-switch.ant-switch-checked {
		background: #71d475;
	}
}
