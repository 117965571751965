.update-pass-form {
	width: 312px;

	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		margin-top: 48px;
	}
}
