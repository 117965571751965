/* stylelint-disable selector-class-pattern */
.form.reset-form-inside {
	width: 100%;

	.form__container-links {
		top: 64px;
		text-align: right;
	}

	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		width: 216px;
		margin: 0 auto !important;
	}

	.cancel {
		width: 216px;
		margin: 13px auto 0;
	}
}
