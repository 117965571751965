%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

%desc {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #303842;
}

.modal-add-social {
	display: flex;

	&__header {
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		@extend %title;
	}

	&__desc {
		@extend %desc;
	}

	&__form {
		//margin: 0 64px;
	}
}
