@import '../../assets/helpers/variables';

.success {
	min-height: 100vh;
	padding: 15px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	background: $gradient;

	&__icon {
		margin: 0 auto 54px;
		height: 80px;
		width: 80px;
		display: block;
	}

	&__icon-close {
		cursor: pointer;
		position: absolute;
		right: 17px;
		top: 17px;
		display: flex;
	}

	&__body {
		display: flex;
		flex-direction: column;
	}

	&__btns {
		margin-top: 48px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.btn-default {
			margin-bottom: 24px;
			width: 336px;
		}
	}

	&__title,
	&__message {
		margin: 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		color: #edf2ff;
		text-align: center;
	}

	&__message {
		max-width: 700px;
	}
}
