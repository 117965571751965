.status-company {
	&-1 {
		background: #edf2ff;
		color: #5e6bf6;
	}

	&-2 {
		background: #fff4e4;
		color: #e99518;
	}

	&-3 {
		background: #ffe4e2;
		color: #ff776f;
	}

	&-4 {
		background: #e3f6e3;
		color: #58c65c;
	}

	&-5 {
		background: #d6d7d9;
		color: #6d7895;
	}
}
