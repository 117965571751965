.dropDownTable {
	width: 100%;

	td {
		border-bottom: 1px solid #e4e4ef;
		padding: 12px 0 !important;

		.dropDownTable__wrap {
			display: flex;
			justify-content: space-between;

			.dropDownTable__text {
				display: flex;
				align-items: center;

				svg {
					fill: #d2d8e8;
				}

				i {
					width: 24px;
					font-style: normal;
					color: #20272e;
					font-size: 12px;
					font-weight: 500;
					line-height: 24px;
					letter-spacing: 0;
					text-align: center;
				}

				span {
					font-size: 12px;
					font-weight: 500;
					line-height: 12px;
					letter-spacing: 0;
					text-align: left;
					color: #20272e;
				}
			}

			.dropDownTable__delete {
				cursor: pointer;
				display: flex;
				align-items: center;

				svg {
					fill: #d2d8e8;

					&:hover {
						cursor: pointer;
						fill: #6d6be5;
						transition: all 0.5s;
					}
				}
			}

			&:hover {
				.dropDownTable__text {
					svg {
						cursor: pointer;
						fill: #6d6be5;
						transition: all 0.5s;
					}
				}
			}
		}
	}
}
