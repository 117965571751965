%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

%desc {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #303842;
}

.modal-reset-password {
	display: flex;

	.ant-modal-content {
		border-radius: 8px;
		height: 402px;
		padding: 0;

		.ant-modal-body {
			padding: 0 !important;
		}
	}

	&__code {
		padding: 50px 64px;
	}

	&__pass {
		padding: 20px 64px;
	}

	&__forgot {
		padding: 69px 64px;
	}

	&__header {
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		@extend %title;
	}

	&__desc {
		@extend %desc;
	}
}
