/* stylelint-disable no-descending-specificity */
/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable selector-class-pattern */
%label {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #6d6be5;
}

%item {
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;
	color: #303842;
}

%name {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #6d7895;
}

.dropdown {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;

	&__triger &__icon-arrow {
		svg {
			path {
				fill: #6d6be5;
				transition: all 0.2s;
			}
		}
	}

	&__triger:hover &__icon-arrow {
		svg {
			path {
				fill: #3843ed;
				transition: all 0.2s;
			}
		}
	}

	&__lang-name {
		cursor: pointer;
		margin-right: 4px;

		@extend %label;
	}

	&__lang-name:hover {
		color: #3843ed;
	}

	&__name {
		cursor: pointer;
		margin-right: 4px;
		max-width: 147px;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: nowrap;
		letter-spacing: 0.01em;

		@extend %name;

		@media screen and (width >= 1920px) {
			max-width: 200px;
		}
	}

	&__icon {
		width: 8px;
		height: 8px;
		display: flex;
		justify-content: center;
		align-items: center;

		&-arrow {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__prefix-icon {
		margin-right: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	span::first-letter {
		text-transform: uppercase;
	}

	svg {
		fill: #d2d8e8;
	}
}

.ant-dropdown {
	&.dropdown-company-wrap {
		.ant-dropdown-menu {
			.ant-dropdown-menu-item {
				max-height: 48px;
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
	}

	&.dropdown-social {
		.ant-dropdown-menu {
			max-height: 160px;
			overflow: auto;

			.ant-dropdown-menu-item {
				padding: 12px 16px;
				min-width: 160px;

				.dropdown-overlay__item {
					display: flex;
					align-items: center;

					svg {
						margin-right: 8px;
					}
				}
			}
		}
	}

	.ant-dropdown-menu {
		padding: 8px 0;
		display: flex;
		flex-direction: column;
		background: #fff;
		box-shadow:
			0 6px 6px rgb(0 0 0 / 8%),
			0 0 80px rgb(0 0 0 / 32%);
		border-radius: 8px;

		&__hide {
			display: none;
		}

		&__list {
			display: flex;
			flex-direction: column;
			box-sizing: content-box;
		}

		&__content {
			overflow: scroll;
			max-height: 144px;
		}

		.ant-dropdown-menu-item {
			cursor: pointer;
			padding: 17px 16px;
			display: flex;
			align-items: center;
			max-height: 74px;

			@extend %item;

			.dropdown-companies {
				display: flex;
				align-items: center;
				gap: 8px;
			}

			svg {
				fill: #d2d8e8;
			}
		}

		.ant-dropdown-menu-item:hover {
			background: #edf2ff;

			svg {
				fill: #6d6be5;
				transition: all 0.5s;
			}
		}

		&__add {
			margin-right: 8px;
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #fff;
			box-shadow: 0 1px 10px rgb(0 0 0 / 16%);
			border-radius: 16px;
		}

		&__text {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
}

.ant-dropdown-open .dropdown-lang {
	&__icon {
		transform: rotate(180deg);
	}
}

.dropdown:hover {
	svg {
		fill: #6d6be5;
		transition: all 0.5s;
	}
}

.ant-dropdown-menu-with-icons {
	.ant-dropdown-menu-item {
		svg {
			margin-right: 5px;
			width: 21px;
		}
	}
}
