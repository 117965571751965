%value {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #303842;
}

%label {
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #303842;
}

.container-dropdown {
	width: 336px;
	right: -10px;
	top: 35px;
	z-index: 1;
	position: absolute;
	display: flex;
	flex-direction: column;
	background: #fff;
	box-shadow:
		0 6px 6px rgb(0 0 0 / 8%),
		0 0 80px rgb(0 0 0 / 32%);
	border-radius: 8px;
	overflow: hidden;

	@media screen and (width <= 390px) {
		width: 87.5vw;
	}

	&_value {
		padding: 8px 11px 8px 16px;
		height: 52px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@extend %value;
	}

	&_label {
		padding: 8px;
		height: 52px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #edf2ff;

		@extend %label;
	}

	&_icons {
		display: flex;
		align-items: center;

		svg {
			margin-left: 12px;
		}
	}
}
