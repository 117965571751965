.user-profile {
	align-items: center;
	display: flex;
	margin-top: 24px;
	margin-bottom: 20px;
	min-height: 40px;
	padding-left: 24px;
	position: relative;

	&__avatar {
		transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

		img {
			border-radius: 50%;
			height: 40px;
			width: 40px;
			background-color: #6d6be5;
		}
	}

	&__block {
		font-size: 12px;
		padding-left: 12px;
		position: relative;
		transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	&__name {
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #303842;
		font-weight: 600;
		margin: 0;
	}

	&__position {
		color: #6d7895;
	}

	&__is-collapsed {
		padding-left: 12px;
	}

	&__is-collapsed &__avatar {
		bottom: 0;
		position: absolute;
		transform: translateX(4px);
		z-index: 5;
	}

	&__is-collapsed &__block {
		font-size: 0;
		left: 65px;
		line-height: 0;
		opacity: 0;
		padding: 0;
		position: absolute;
	}
}
