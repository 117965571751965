/* stylelint-disable selector-class-pattern */
/* stylelint-disable no-descending-specificity */
.input-default {
	// custom ant-picker input
	.ant-picker {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 40px;
		box-sizing: border-box !important;
		color: transparent;
		list-style: none;
		display: inline-flex;
		background: transparent;
		border: none;
		transition: none;

		input {
			cursor: pointer !important;
		}

		.ant-picker-input {
			input {
				outline: none;
				padding: 0 16px;
				width: 100%;
				height: 40px;
				display: flex;
				flex-direction: column;
				border: 1px solid #cbd5e2;
				box-sizing: border-box;
				border-radius: 4px;
			}
		}

		.ant-picker-input:hover {
			input {
				border: 1px solid #4e5af2;
			}
		}

		.ant-picker-suffix,
		.ant-picker-clear {
			position: absolute;
			right: 10px;
		}
	}

	.ant-picker:focus-within:not(.input-default__error) {
		input {
			box-sizing: border-box;
			border: 2px solid #3843ed !important;
		}
	}

	.ant-picker-focused {
		box-shadow: none;
	}

	.ant-picker.input-default__error {
		input {
			border: none;
			padding: 0 !important;
			padding: 0 15px;
		}
	}
}
