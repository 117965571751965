$maxWidthContainer: 1464;
$maxWidth: 1920;

$letterSpacing: 0.03em;

$fontFamily: 'Montserrat', sans-serif;
$transition: cubic-bezier(0.65, 0.05, 0.36, 1);
$colorWhite: #fff;
$gradient: linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);

$textMain: #303842;
$textDark: #20272e;

$colorPrimaryMain: #4e5af2;
$colorPrimaryDark: #3843ed;
$colorPrimaryLight: #5e6bf6;
$colorPrimaryExtraLight: #dcdefc;

$colorSecondaryMain: #8d8bed;
$colorSecondaryDark: #6d6be5;
$colorSecondaryLight: #a09ef2;
$colorSecondaryExtraLight: #edf2ff;

$colorErrorMain: #ff776f;
$colorErrorDark: #ff5e55;
$colorErrorLight: #ff8a81;
$colorErrorExtraLight: #ffe4e2;

$colorGreyMain: #8d97b0;
$colorGreyDark: #6d7895;
$colorGreyLight: #d2d8e8;
$colorGreyExtraLight: #edf2ff;

$colorWarningMain: #ffdd90;
$colorWarningDark: #ffc864;
$colorWarningLight: #ffe6a3;
$colorWarningExtraLight: #fff8e9;

$colorSuccessMain: #71d575;
$colorSuccessDark: #58c65c;
$colorSuccessLight: #84df88;
