/* stylelint-disable selector-class-pattern */
%label {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #20272e;
}

%tag {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #fff;
}

%status {
	font-weight: 500;
	font-size: 10px;
	line-height: 24px;
	letter-spacing: 0.15px;
}

%remove {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #303842;
}

.company-info-block {
	padding-bottom: 24px;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__body {
		display: flex;
		flex-direction: column;
		grid-gap: 24px;
	}

	&__footer {
		display: flex;
		justify-content: flex-end;

		svg {
			fill: #d2d8e8;
			transition: all 0.2s;
		}

		svg:hover {
			cursor: pointer;
			fill: #3843ed;
			transition: all 0.2s;
		}

		span {
			margin-left: 8px;

			@extend %remove;
		}
	}

	&__buttons {
		button {
			margin-top: 24px;
		}

		.mn {
			margin-top: 0;
		}
	}

	&__tag-wrap {
		display: flex;

		.company-info-block__tags {
			width: 50%;
		}
	}

	&__tags {
		//margin-bottom: 24px;
		display: flex;
		flex-direction: column;
	}

	&__list {
		grid-gap: 12px;
		display: flex;
		flex-wrap: wrap;
	}

	&__label {
		margin-bottom: 12px;

		@extend %label;
	}

	&__tag {
		padding: 0 12px;

		@extend %tag;

		background: #8d8bed;
		border-radius: 20px;
	}

	&__status {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__status-value {
		padding: 0 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 20px;

		@extend %status;

		&-1 {
			background: #edf2ff;
			color: #5e6bf6;
		}

		&-2 {
			background: #fff4e4;
			color: #e99518;
		}

		&-3 {
			background: #ffe4e2;
			color: #ff776f;
		}

		&-4 {
			background: #e3f6e3;
			color: #58c65c;
		}

		&-5 {
			background: #d6d7d9;
			color: #6d7895;
		}
	}

	&__owner-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.user-profile {
			padding: 0;
			margin: 0;

			&__block {
				display: flex;
				flex-direction: column;
			}

			&__position {
				font-weight: 500;
				font-size: 10px;
				line-height: 12px;
				color: #47525f;
			}
		}
	}

	&__drop-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		grid-gap: 12px;

		.dropdown__name {
			color: #303842;
		}
	}

	.btn-remove {
		cursor: pointer;
		display: flex;
		align-items: center;

		svg {
			fill: #6d7895;

			&:hover {
				fill: #4e5af2;
			}
		}
	}
}
