@keyframes spin {
	from {
		transform: scale(1) rotate(0deg);
	}

	to {
		transform: scale(1) rotate(360deg);
	}
}

@keyframes spinleft {
	from {
		transform: scale(1) rotate(0deg);
	}

	to {
		transform: scale(1) rotate(-360deg);
	}
}

@keyframes opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes dropdown {
	0% {
		transform: scaleY(0);
	}

	80% {
		transform: scaleY(1.1);
	}

	100% {
		transform: scaleY(1);
	}
}

@keyframes dropdown-up {
	from {
		transform: scale(1) rotate(0deg);
	}

	to {
		transform: scale(1) rotate(-180deg);
	}
}

@keyframes dropdown-back {
	from {
		transform: scale(1) rotate(-180deg);
	}

	to {
		transform: scale(1) rotate(0deg);
	}
}

.animation-opacity {
	animation: opacity 0.3s linear;
}

.animation-dropdown {
	animation: dropdown 0.3s ease-in-out forwards;
}

.animation-dropdown-up {
	animation: dropdown-up 0.3s ease-in-out forwards;
}

.animation-dropdown-back {
	animation: dropdown-back 0.3s ease-in-out forwards;
}

.icon-spinner {
	display: flex;
	animation: spin 1.5s infinite linear;
}

.icon-spinner-left {
	display: flex;
	animation: spinleft 1.5s infinite linear;
}
