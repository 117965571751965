.btn-back {
	cursor: pointer;
	display: flex;
	align-items: center;
	//justify-content: space-between;

	&__icon {
		margin-right: 11px;
		display: flex;

		svg {
			stroke: #6d7895;
		}
	}

	&__text {
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: #6d7895;
	}
}
