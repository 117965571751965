/* stylelint-disable selector-class-pattern */
.form.code-form {
	width: 336px;

	@media screen and (width <= 768px) {
		width: 100%;
	}
}

.code-form {
	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		margin: 56px 0;
	}
}

.form.code-form-inside {
	width: 100%;

	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		width: 216px;
		margin: 0 auto !important;
	}

	.buttons {
		margin-top: 48px;

		.cancel {
			width: 216px;
			margin: 15px auto 0;
		}
	}
}
