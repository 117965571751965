%text {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #4e5af2;
}

.btn-add {
	cursor: pointer;
	padding: 12px 16px;
	width: 100%;
	min-height: 56px;
	display: flex;
	align-items: center;
	background: #edf2ff;
	border-radius: 8px;
	transition: background 300ms linear;

	&__plus {
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		box-shadow: 0 1px 10px rgb(0 0 0 / 16%);
		border-radius: 16px;
	}

	&__text {
		margin: 0 8px;

		@extend %text;
	}

	&:hover {
		background: #4e5af2;
		transition: background 300ms linear;
	}

	&:hover &__text {
		color: #fff;
		transition: color 300ms linear;
	}
}
