.tooltip-structure-title {
	max-width: 552px;
	width: 552px;
	font-size: 10px;
	font-weight: 600;
	line-height: 10px;
	letter-spacing: 0;
	text-align: left;

	&__title {
		margin-bottom: 8px;
	}

	&__ul {
		margin: 8px 12px;
	}

	&__li {
		margin-bottom: 8px;
		list-style: decimal;

		p {
			display: inline;

			b {
				color: #8d8bed;
				font-weight: 700;
			}
		}
	}
}
