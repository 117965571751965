/* stylelint-disable selector-class-pattern */
%link {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #4e5af2;
}

%label {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #20272e;
}

%btn-text {
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #6d7895;
}

.edit-profile-form {
	width: 100%;

	&__container {
		margin-bottom: 32px;
	}

	&__container-label {
		margin-bottom: 12px;
		display: flex;
		align-items: center;

		@extend %label;

		.default-tooltip {
			margin-left: 9px;
			display: flex;
		}

		svg {
			cursor: pointer;
			transition: all 0.2s;
			fill: #8d8bed;
		}

		svg:hover {
			transition: all 0.2s;
			fill: #3843ed;
		}
	}

	&__row {
		width: 100%;
		display: flex;
		//
		grid-gap: 24px;

		@media screen and (width <= 768px) {
			flex-wrap: wrap;
			grid-gap: 0;
		}
	}

	.input-default,
	&__block {
		width: 100%;
	}

	.input-default {
		&__icons {
			right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__block {
		position: relative;

		.confirm-phone {
			cursor: pointer;
			position: absolute;
			top: 32px;
			right: 8px;

			@extend %link;
		}
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		grid-gap: 24px;

		.btn-default {
			max-width: 116px;
		}
	}

	&__btn-text {
		@extend %btn-text;

		cursor: pointer;
	}

	.social-block .input-default__icons {
		left: 17px;
		justify-content: flex-start;
	}

	&__social-select {
		width: 100%;
		display: flex;
		flex-direction: column;

		&__body {
			display: flex;
			align-items: flex-end;
		}

		.dropdown {
			height: 40px;
			min-width: 72px;
			display: flex;
			justify-content: center;
			border: 1px solid #cbd5e2;
			border-right: none;
			border-radius: 4px 0 0 4px;

			&:hover {
				cursor: pointer;
				background: #edf2ff;
			}

			&__name {
				display: flex;
				margin-right: 8px;

				svg {
					width: 21px;
				}
			}
		}

		.input-default__input {
			width: 100%;
			margin-bottom: 0;

			input {
				border-radius: 0 4px 4px 0;
			}
		}
	}
}
