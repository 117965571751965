.reg-form {
	width: 336px;

	@media screen and (width <= 768px) {
		width: 100%;
	}

	button[type='submit'] {
		margin: 32px 0;
	}
}
