%title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #20272e;
}

%desc {
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #47525f;
}

.modal-notification-component {
	height: 24px;
	position: relative;

	&__active {
		background: #ff776f;
		border-radius: 50%;
		box-shadow: 0 0 0 2px #edf2ff;
		height: 6px;
		position: absolute;
		right: 0;
		top: 0;
		width: 6px;
		z-index: 5;
	}
}

.modal-notification {
	display: flex;
	justify-content: center;
	align-items: center;
	top: 74px;
	right: 24px;
	left: auto;
	margin-left: auto;
	margin-right: 0;

	.ant-modal-content {
		overflow: auto;
		border-radius: 8px;
		padding: 0;
		width: 505px;
	}

	.ant-modal-close {
		right: 12px;
		top: 12px;
		width: 24px;
		height: 24px;

		&:focus,
		&:hover {
			background: none;
			color: #4e5af2;
			text-decoration: none;

			svg {
				fill: #4e5af2;
			}
		}

		.ant-modal-close-icon {
			font-size: 14px;
		}
	}

	&__header {
		margin-bottom: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__title {
		margin-bottom: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		@extend %title;

		.notificationTitleCount {
			padding: 4px;
			min-width: 24px;
			border-radius: 8px;
			background: #4e5af2;
			margin-left: 10px;
			font-size: 12px;
			font-weight: 600;
			color: white;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;

			&.hidden {
				display: none;
			}
		}
	}

	&__date {
		padding: 12px 24px;
		height: 38px;
		line-height: 14.4px;
		color: #6d7895;
	}

	&__list {
		max-height: 528px;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
	}

	&__item {
		padding: 8px 24px;
		border-bottom: 1px solid #f2f6ff;
		background: #f2f6ff;

		.info-block__item {
			background: #f2f6ff;
		}

		&.viewed {
			background: white;
			transition: all 0.2s;

			.info-block__item {
				transition: all 0.2s;
				background-color: white;
			}
		}
	}
}
