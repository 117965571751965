.lc-messages-container {
	padding: 0 24px;
	display: flex;
	flex-direction: column;

	.lc-messages-list {
		margin-top: 24px;
		max-height: 240px;
		overflow-y: auto;
	}
}
