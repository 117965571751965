@keyframes line {
	100% {
		transform: translateX(100%);
	}
}

.skeleton-companies {
	width: 100%;
	position: relative;

	&__mask {
		position: absolute;
		z-index: 1;
		inset: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(#fff, 0) 0,
			rgba(#fff, 0.2) 20%,
			rgba(#fff, 0.5) 60%,
			rgba(#fff, 0)
		);
		animation: line 2s infinite;
	}

	&__img {
		width: 100%;
	}
}
