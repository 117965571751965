@import '@assets/colors.scss';

%text {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: $grey2;
}

.tab_pane_title {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #20272e;
	margin-bottom: 24px;

	&__empty {
		box-shadow: none;
		background-color: none;
	}
}

.forget {
	display: flex;
	position: absolute;
	right: 0;
	bottom: -25px;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: #4e5af2;
	text-decoration-line: underline;
	cursor: pointer;
}

.settings-tabs {
	width: 100%;

	&.settings-empty {
		.ant-tabs-content {
			background: transparent;
			box-shadow: none;
			padding: 0;
		}
	}

	@media screen and (width <= 336px) {
		width: 100%;
	}

	.ant-tabs-nav-list {
		width: 100%;
	}

	.ant-tabs-tab {
		margin: 0;
		padding: 12px 36px;
		justify-content: center;

		@extend %text;
	}

	.ant-tabs-nav {
		margin: 0 0 24px;
	}

	.ant-tabs-tab-active {
		//font-weight: 500;
		color: $blue;
	}

	.ant-tabs-ink-bar {
		height: 4px !important;
		background: $blue;
		border-radius: 4px 4px 0 0;
	}

	.ant-tabs-nav::before {
		border-bottom: 1px solid $grey;
	}

	.company_no-verifier {
		font-size: 12px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0;
		text-align: center;
		height: 48px;
		padding: 12px 0;
		display: flex;
		align-content: center;
		justify-content: center;
		color: #303842;
	}

	.ant-tabs-content {
		padding: 24px;
		outline: none;
		min-height: 458px;
		background: #fff;
		box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
		border-radius: 8px;

		.my-companies {
			padding: 24px;
		}

		.company_empty {
			background-color: #8d8bed;
			width: 336px;
			height: 124px;
			padding: 24px;
			border-radius: 8px;
			gap: 10px;
			display: flex;
			flex-direction: column;

			&__title {
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				letter-spacing: 0;
				text-align: left;
				color: white;
			}

			&__button {
				cursor: pointer;
				height: 40px;
				display: flex;
				align-items: center;
				gap: 8px;

				&__icon {
					height: 32px;
					width: 32px;
					border-radius: 32px;
					background: #fff;
					box-shadow: 0 1px 10px 0 #00000029;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 10px;
						height: 10px;
					}
				}

				&__text {
					font-size: 12px;
					font-weight: 600;
					line-height: 24px;
					letter-spacing: 0;
					text-align: left;
					color: #dcdefc;
				}
			}
		}
	}

	.padding-0 {
		padding: 0;
		outline: none;
	}

	/* TODO bug on resolution */

	.ant-tabs-nav-operations {
		display: none !important;
	}
}

.settings-tabs-empty {
	&__text {
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		color: #303842;
	}

	.ant-tabs-nav {
		margin: 24px 0;
	}

	.ant-tabs-content {
		outline: none;
		padding: 12px;
		min-height: 458px;
		display: flex;
		justify-content: center;
		background: transparent;
		box-shadow: none;
		border-radius: none;
	}
}
