.form-template {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	height: 100%;
	min-height: 100vh;

	&__body {
		padding: 48px 24px 24px;
		min-height: 100vh;
		overflow: auto;
		display: flex;
		flex-direction: column;
		flex: 1;

		@media screen and (width <= 446px) {
			overflow: unset;
		}
	}

	&__body-center {
		padding-top: 92px;
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
		width: 100%;
	}

	&__box {
		padding: 32px 0;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.btn-back {
			max-width: 73px;

			&__text {
				color: #a09ef2;
			}

			svg {
				stroke: #a09ef2;
			}
		}

		.btn-back:hover {
			span {
				color: #fff;
			}

			svg {
				stroke: #fff;
			}
		}
	}
}
