@import '@organisms/tables/HomeDataTable/style.scss';

%title {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #20272e;
}

%header {
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	color: #20272e;
}

%item {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	color: #20272e;
}

%status {
	font-weight: 500;
	font-size: 10px;
	line-height: 24px;
	letter-spacing: 0.15px;
	text-align: center;
}

.modal-search__wrap {
	height: 100%;
}

.lc-verification-companies-table {
	width: 100%;
	height: 100%;
	//min-height: 572px;
	display: flex;
	flex-direction: column;
	border-radius: 8px;

	&.auto {
		height: calc(100% - 72px);
	}

	.ant-table {
		margin-bottom: 18px;
	}

	.ant-table,
	.ant-table-body {
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	.ant-table-body {
		padding-bottom: 8px;

		table {
			padding-bottom: 48.5px;
		}
	}

	&__empty {
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		color: #303842;
		//position: absolute;
		left: 0;
		right: 0;
		margin-top: 24px;
	}

	&__empty.ant-typography {
		position: static;
		height: 100%;
		min-height: 508px;
		background: #fff;
		align-items: flex-start;
		padding-top: 12px;
		padding-bottom: 12px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		margin-bottom: 18px;
	}

	&__title {
		display: flex;
		flex-direction: column;
	}

	&__container {
		width: 100%;
		height: 100%;

		.ant-spin-nested-loading {
			height: 100%;

			.ant-spin-container {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
		// .ant-pagination {
		// 	display: none;
		// }
	}

	.ant-table-cell {
		border-bottom: 1px solid #f0f0f0;

		&.ant-table-cell-scrollbar {
			background: transparent;
			box-shadow: none;
			padding: 0;
		}
	}

	.ant-table-thead table {
		border-radius: 0;
	}

	.ant-table-thead > tr > th {
		height: 48px;
		background-color: #fff;

		@extend %header;
	}

	&__column {
		height: 48px;
		background-color: #fff;

		@extend %item;

		border-bottom: 1px solid #f0f0f0;

		&-container {
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				cursor: pointer;
			}
		}

		&-id {
			width: 60px;
		}

		&-name {
			min-width: 243px;
		}

		&-activity {
			min-width: 345px;
		}

		&-code {
			min-width: 127px;
		}

		&-date {
			min-width: 125px;
		}

		&-files {
			min-width: 127px;
		}

		&-status {
			min-width: 157px;
		}

		&-link {
			min-width: 100px;

			a {
				display: flex;
			}

			svg {
				transition: all 0.2s;
				fill: #d2d8e8;
			}

			svg:hover {
				transition: all 0.2s;
				fill: #3843ed;
			}
		}

		&-date-block {
			display: flex;
			flex-direction: column;

			.table-date {
				line-height: 100%;
			}

			.table-time {
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
				color: #47525f;
			}
		}
	}

	&__column-id &__title,
	&__column-files &__title,
	&__column-link &__title,
	&__column-status &__title {
		text-align: center;
	}

	&__column-id,
	&__column-files,
	&__column-link,
	&__column-status {
		.ant-table-column-sorters {
			justify-content: center !important;
		}
	}

	&__navigation {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__status {
		margin: 0 auto;
		width: 121px;
		padding: 0 8px;
		border-radius: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: nowrap;

		@extend %status;

		&-active {
			background: #e3f6e3;
			border-radius: 20px;
			color: #58c65c;
		}
	}

	.ant-table-column-title {
		flex: none;
		display: flex;
	}

	.ant-table-cell.ant-table-cell-row-hover {
		background-color: transparent;
		border-width: 2px 0;
		border-style: solid;
		border-color: #6d6be5;
		box-sizing: border-box;
	}

	.ant-table-cell-row-hover &__column {
		border-bottom: none !important;
	}

	.ant-table-row {
		border-width: 2px 0;
		border-style: solid;
		border-color: #6d6be5;
	}

	.ant-table-thead
		> tr
		> th:not(
			:last-child,
			.ant-table-selection-column,
			.ant-table-row-expand-icon-cell,
			[colspan]
		)::before {
		display: none;
	}

	.ant-table-column-sorters {
		//padding: 0 10px;
		justify-content: flex-start;
	}

	.ant-table-column-sort {
		background-color: #fff;
	}

	.ant-table-column-has-sorters:hover {
		background-color: #fff !important;
	}

	.ant-table-column-sorter {
		display: flex;
	}

	.ant-table-column-sorter-up,
	.ant-table-column-sorter-down {
		font-size: 9px;
	}

	.w-230 {
		min-width: 230px;
	}

	.w-125 {
		min-width: 125px;
	}

	.w-135 {
		min-width: 135px;
	}

	.w-307 {
		min-width: 307px;
	}

	.w-153 {
		min-width: 153px;
	}

	.w-151 {
		min-width: 151px;
	}

	.w-110 {
		min-width: 110px;
	}

	.w-50 {
		min-width: 50px;
	}

	.w-254 {
		min-width: 254px;
	}
}

.ant-tabs-content-holder {
	.lc-verification-companies-table__empty.ant-typography {
		margin-top: 0;
		background: #fff;
	}

	.lc-verification-companies-table__column-name {
		cursor: pointer;
	}

	.lc-verification-companies-table__column-status {
		padding-left: 0;
	}
}

.table-default {
	&__pagination {
		right: 0;
		margin: 20px 0;
		//display: flex;
		&.right {
			margin-left: auto;
		}
	}
}

.images-name {
	display: flex;
}

.verification-table-header {
	min-height: 64px;
	padding: 0 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f0f0f0;
	background: #fff;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;

	&__empty {
		border-bottom: none;
	}

	&__title {
		@extend %title;
	}
}

.search-dropdown {
	min-width: 135px !important;
}
