@import '../helpers/mixins';

.authentication {
	position: relative;

	& .ant-tabs-nav-operations {
		display: none !important;
	}

	& .ant-tabs-nav {
		margin-bottom: 64px !important;
	}

	&__content {
		height: 100vh;
		overflow: auto;
		padding-top: 105px;
		align-items: center;

		@media (height <= 768px) {
			padding-top: 40px;
		}
	}
}

.content {
	background-color: #fff;

	&__container {
		width: 100%;
		max-width: 336px;

		& .ant-tabs {
			overflow: inherit !important;
		}
	}
}
